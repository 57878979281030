import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CORS_ERROR_MESSAGE } from './CONSTANTS';
import { useConfirm } from './context/Confirm';
import { useUser } from './context/User';

export const QueryWrapper = ({ children }: any) => {
  const { updateError } = useUser();
  const { handleSetConfirmProps } = useConfirm();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        onError: (error: any) => {
          if (error?.error === CORS_ERROR_MESSAGE) {
            updateError(error?.error);
          } else {
            handleSetConfirmProps({
              title: 'Error',
              message: error?.error,
            });
          }
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
