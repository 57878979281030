import React from 'react';

// create the language context with default selected language
export const MapContext = React.createContext({
  mapIsShown: false,
  toggleMapIsShown: (newState: boolean) => {},
});

export function useMap() {
  return React.useContext(MapContext);
}

type MapProviderType = {
  children: JSX.Element;
};

// it provides the language context to app
export function MapProvider({ children }: MapProviderType) {
  const [mapIsShown, setMapIsShown] = React.useState(false);

  const toggleMapIsShown = (newState: boolean) => {
    setMapIsShown(newState);
  };

  const provider = {
    mapIsShown,
    toggleMapIsShown,
  };

  return <MapContext.Provider value={provider}>{children}</MapContext.Provider>;
}
