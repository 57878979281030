import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Loading } from '../../components/Loading';
import { TextOnly } from '../../components/Text';
import ToggleButton from '../../components/ToggleButton';
import {
  USER_STATE,
  USER_TYPE,
  USER_TYPE_LIST
} from '../../CONSTANTS';
import { useConfirm } from '../../context/Confirm';
import { useUser } from '../../context/User';
import {
  CreateUserStateParams,
  GetUsersResponse,
  SetUserStateParams,
  SetUserTypeParams,
  getPartners,
  getPartnerInfo,
  updateUser,
  UpdateUserParams,
  siteAdminCreateUser,
  siteAdminGetUsers,
  siteAdminSetUserState,
  siteAdminSetUserType,
  oemAdminCreateUser,
  oemAdminGetUsers,
  oemAdminSetUserState,
  oemAdminSetUserType,
  cpoAdminCreateUser,
  cpoAdminGetUsers,
  cpoAdminSetUserState,
  cpoAdminSetUserType,
  moAdminCreateUser,
  moAdminGetUsers,
  moAdminSetUserState,
  moAdminSetUserType,
} from '../../libs/db-lib';
import { UpdateUser, User, Partner } from '../../types';
import { ManageUsers } from './ManageUsers';
import * as Yup from 'yup';
import moment from 'moment';

export interface UserTableView {
  userName: string;
  name: string;
  addedOn: string;
  addedBy: string;
  partnerName?: string;
  userType: USER_TYPE;
  userState: React.Component | USER_STATE;
  crud: React.Component | JSX.Element;
}

export enum MANAGE_USERS_DIALOGS {
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  FILTER = 'FILTER',
}

export const ManageUsersContainer = () => {
  // TODO: userError will log out user, might move error to local state or a new global error - Adam Curl 2022-02-01
  const { user, isSite, isOEM, isCPO, isMO, partnerID } =
    useUser();
  const { handleSetConfirmProps } = useConfirm();

  const [currentDialog, setCurrentDialog]     = useState<MANAGE_USERS_DIALOGS | null>(null);
  const [users, setUsers]                     = useState<Array<UserTableView>>([]);
  const [userTypeFilter, setUserTypeFilter]   = useState<USER_TYPE[]>([]);
  const [userStateFilter, setUserStateFilter] = useState<USER_STATE[]>([]);
  const [partnerFilter, setPartnerFilter]     = useState<string[]>([]);
  const [selectedUser, setSelectedUser]       = useState<UpdateUser | null>(null);

  const oemUserTypeOptions  = [USER_TYPE.OEM_ADMIN, USER_TYPE.OEM_USER];
  const cpoUserTypeOptions  = [USER_TYPE.CPO_ADMIN, USER_TYPE.CPO_USER];
  const moUserTypeOptions   = [USER_TYPE.MO_ADMIN, USER_TYPE.MO_USER];
  const userTypeOptions     = [oemUserTypeOptions, cpoUserTypeOptions, moUserTypeOptions];

  const { data: partnerInfo, isLoading: isLoadingPartnerInfo } = useQuery(
    'getPartnerInfo',
    () => getPartnerInfo({ partnerID: partnerID }),
    {
      enabled: !!partnerID,
    }
  );
  const partnerName = partnerInfo?.partnerName;

  const setAdminGetUserList = (data: GetUsersResponse) => {
    const userList = data?.userList?.map((user: User) => {
      return {
        id: user.userID,
        userName: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        addedOn: moment(user.createdOn).format('MM-DD-YYYY'),
        addedBy: user.addedBy,
        partnerName: partnerName || partners?.partnerList.find((partner: Partner) => partner.partnerID === user.partnerID)?.partnerName || '', // IMPROVE THIS
        userType: user.userType,
        userState: user.userState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedUser(user);
                toggleEditUserDialog();
              }}
              disabled={user.userState !== USER_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    setUsers(userList);
  };

  // #region 1 queries
  const { data: partners, isLoading: isLoadingPartners } = 
    useQuery('getPartners', getPartners, {
    enabled: !!isSite,
  });
  const { isLoading: siteAdminGetUsersIsLoading, refetch: siteAdminGetUsersRefetch  } = 
    useQuery('siteAdminGetUsers', siteAdminGetUsers, {
      enabled: !!isSite && !isLoadingPartners,
      onSuccess: (data) => {
        setAdminGetUserList(data);
      },
    });
  const { isLoading: oemGetUsersIsLoading, refetch: oemGetUsersRefetch } =
    useQuery('oemGetUsers', oemAdminGetUsers, {
      enabled: !!isOEM,
      onSuccess: (data) => {
        setAdminGetUserList(data);
      },
  });
  const { isLoading: cpoGetUsersIsLoading, refetch: cpoGetUsersRefetch } =
    useQuery('cpoGetUsers', cpoAdminGetUsers, {
      enabled: !!isCPO,
      onSuccess: (data) => {
        setAdminGetUserList(data);
      },
  });
  const { isLoading: moGetUsersIsLoading, refetch: moGetUsersRefetch } =
    useQuery('moGetUsers', moAdminGetUsers, {
      enabled: !!isMO,
      onSuccess: (data) => {
        setAdminGetUserList(data);
      },
  });
  const getUsersRefetch = () => {
    // IMPROVE THIS
    if (isSite) {
      siteAdminGetUsersRefetch();
    } else if (isOEM) {
      oemGetUsersRefetch();
    } else if (isCPO) {
      cpoGetUsersRefetch();
    } else if (isMO) {
      moGetUsersRefetch();
    }
  };
  // #endregion 1

  // #region 2 mutations
  const updateUserMutation = useMutation<
  { error?: string },
  Error,
  UpdateUserParams
>((userFields) => updateUser(userFields), {
  onSuccess: (data: any) => {
    if (data?.error) {
      toast.error(data.error, {
        autoClose: false,
        containerId: 'standard',
      });
      return;
    }
    toast.success(TextOnly('updateSuccess'), {
      containerId: 'standard',
    });
    editUserFormik.resetForm();
    setSelectedUser(null);
    setCurrentDialog(null);
    getUsersRefetch();
  },
  onError: (error) => {
    toast.error(error.message, {
      autoClose: false,
      containerId: 'standard',
    });
  },
});

  const siteAdminCreateUserMutation = useMutation(
    (userFields: CreateUserStateParams) => siteAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        siteAdminAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );
  const siteAdminSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => siteAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });
  
  const siteAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => siteAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const oemAdminCreateUserMutation = useMutation(
    (userFields: CreateUserStateParams) => oemAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        adminAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );
  const cpoAdminCreateUserMutation = useMutation(
    (userFields: CreateUserStateParams) => cpoAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        adminAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );
  const moAdminCreateUserMutation = useMutation(
    (userFields: CreateUserStateParams) => moAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        adminAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );
  
  const oemSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => oemAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });
  const cpoSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => cpoAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });
  const moSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => moAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const oemAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => oemAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const cpoAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => cpoAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const moAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => moAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });
  // #endregion 2

  // #region 3 column
  const adminColumns = [
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Added On',
      accessor: 'addedOn',
    },
    {
      Header: 'Added By',
      accessor: 'addedBy',
    },
    {
      Header: 'Partner',
      accessor: 'partnerName',
    },
    {
      Header: 'Role',
      accessor: 'userType',
      filter: 'equals',
      Cell: ({ row: { original } }: any) => (
        <div
          className={`c-select c-select--no-border c-select--wide u-padding-none ${
            original.userType === USER_TYPE.SITE_ADMIN ||
            original.userState !== USER_STATE.ACTIVE ||
            user?.userID === original.id
              ? 'c-select--disabled'
              : ''
          }`}
        >
          <select
            id={`role-${original.id}`}
            name={`role-${original.id}`}
            value={original.userType}
            disabled={
              original.userType === USER_TYPE.SITE_ADMIN ||
              original.userState !== USER_STATE.ACTIVE ||
              user?.userID === original.id
            }
            onChange={(e) => {
              const value = e.target.value;
              handleSetConfirmProps({
                title: TextOnly('confirmChangeRole'),
                message: TextOnly('confirmChangeRoleMessage', {
                  userName: original.userName,
                  userType: value,
                }),
                handleConfirm: () => {
                  // IMPROVE THIS
                  if (isSite) {
                    siteAdminSetUserTypeMutation.mutate({
                      userID: original.id,
                      userType: value,
                    });
                  } else if (isOEM) {
                    oemAdminSetUserTypeMutation.mutate({
                      userID: original.id,
                      userType: value,
                    });
                  } else if (isCPO) {
                    cpoAdminSetUserTypeMutation.mutate({
                      userID: original.id,
                      userType: value,
                    });
                  } else if (isMO) {
                    moAdminSetUserTypeMutation.mutate({
                      userID: original.id,
                      userType: value,
                    });
                  }
                },
              });
            }}
          >
            { original.userType &&
              (userTypeOptions.find(element => element.includes(original.userType)))
              ?.map((userType) => (
                  <option key={userType} value={userType}>
                    {TextOnly(userType)}
                  </option>
                ))
            }
          </select>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'userState',
      filter: 'equals',
      minWidth: 100,
      Cell: ({ row: { original } }: any) => (
        <ToggleButton
          isActive={original.userState === USER_STATE.ACTIVE}
          title="set user active/inactive"
          handleToggle={() => {
            // IMPROVE THIS
            if (isSite) {
              siteAdminSetUserStateMutation.mutate({
                userID: original.id,
                userState:
                  original.userState === USER_STATE.ACTIVE
                    ? USER_STATE.INACTIVE
                    : USER_STATE.ACTIVE,
              });
            } else if (isOEM) {
              oemSetUserStateMutation.mutate({
                userID: original.id,
                userState:
                  original.userState === USER_STATE.ACTIVE
                    ? USER_STATE.INACTIVE
                    : USER_STATE.ACTIVE,
              });
            } else if (isCPO) {
              cpoSetUserStateMutation.mutate({
                userID: original.id,
                userState:
                  original.userState === USER_STATE.ACTIVE
                    ? USER_STATE.INACTIVE
                    : USER_STATE.ACTIVE,
              });
            } else if (isMO) {
              moSetUserStateMutation.mutate({
                userID: original.id,
                userState:
                  original.userState === USER_STATE.ACTIVE
                    ? USER_STATE.INACTIVE
                    : USER_STATE.ACTIVE,
              });
            }
          }}
        />
      ),
    },
    {
      Header: 'Edit User',
      accessor: 'crud',
      disableSortBy: true,
      maxWidth: 50,
    },
  ];
  // #endregion 3

  // #region 4 formik
  const formik = useFormik({
    initialValues: {
      userTypeFilter: userTypeFilter,
      userStateFilter: userStateFilter,
      partnerFilter: partnerFilter,
    },
    onSubmit: async (values) => {
      setUserTypeFilter(values.userTypeFilter);
      setUserStateFilter(values.userStateFilter);
      setPartnerFilter(values.partnerFilter);
      toggleFilterDialog();
    },
    enableReinitialize: true,
  });

  const siteAdminAddUserValidation = Yup.object().shape({
    userName: Yup.string()
      .max(50)
      .matches(
        /^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$/,
        TextOnly('userNameFormatRequirements')
      )
      .required(TextOnly('requiredField', { field: TextOnly('username') })),
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?(){}=:;"'<>[\]\\]).{8,}$/
      )
      .required(TextOnly('requiredField', { field: TextOnly('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], TextOnly('passwordMismatch'))
      .required(TextOnly('requiredField', { field: 'Password Confimation' })),
    userType: Yup.string().required(
      TextOnly('requiredField', { field: 'role' })
    ),
    partnerID: Yup.string().when('userType', {
      is: (value: USER_TYPE) => value !== USER_TYPE.SITE_ADMIN,
      then: Yup.string().required(
        TextOnly('requiredField', { field: TextOnly('partner') })
      ),
    }),
  });

  const siteAdminAddUserFormik = useFormik({
    initialValues: {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      userType: '',
      partnerID: '',
    },
    validationSchema: siteAdminAddUserValidation,
    onSubmit: async (values) => {
      siteAdminCreateUserMutation.mutate({
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: values.userType as USER_TYPE,
        partnerID: values.partnerID !== '' ? values.partnerID : undefined,
      });
    },
  });

  const adminAddUserValidation = Yup.object().shape({
    userName: Yup.string()
      .max(50)
      .matches(
        /^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$/,
        TextOnly('userNameFormatRequirements')
      )
      .required(TextOnly('requiredField', { field: TextOnly('username') })),
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?(){}=:;"'<>[\]\\]).{8,}$/
      )
      .required(TextOnly('requiredField', { field: TextOnly('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], TextOnly('passwordMismatch'))
      .required(TextOnly('requiredField', { field: 'Password Confimation' })),
    userType: Yup.string().required(
      TextOnly('requiredField', { field: 'role' })
    ),
  });

  const adminAddUserFormik = useFormik({
    initialValues: {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      confirmPassword: '',
      password: '',
      userType: isOEM ? USER_TYPE.OEM_USER : 
                isCPO ? USER_TYPE.CPO_USER : 
                isMO ? USER_TYPE.MO_USER :
                '', //IMPROVE THIS
    },
    validationSchema: adminAddUserValidation,
    onSubmit: async (values) => {
      //IMPROVE THIS
      const mutateObj = {
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: values.userType as USER_TYPE,
        partnerID: partnerID,
      }
      if (isOEM) {
        oemAdminCreateUserMutation.mutate(mutateObj);
      } else if (isCPO) {
        cpoAdminCreateUserMutation.mutate(mutateObj);
      } else if (isMO) {
        moAdminCreateUserMutation.mutate(mutateObj);
      }      
    },
  });

  const editUserValidation = Yup.object().shape({
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
  });

  const editUserFormik = useFormik({
    initialValues: {
      userID: selectedUser?.userID || '',
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      email: selectedUser?.email || '',
    },
    validationSchema: editUserValidation,
    onSubmit: async (values) => {
      updateUserMutation.mutate({
        userID: values.userID,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      });
    },
    enableReinitialize: true,
  });

  // #endregion 4

  // #region 5 funcitons

  const toggleFilterDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.FILTER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.FILTER);
  };

  const toggleAddUserDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.ADD_USER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.ADD_USER);
  };

  const toggleEditUserDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.EDIT_USER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.EDIT_USER);
  };

  const resetFilters = () => {
    setUserTypeFilter([]);
    setUserStateFilter([]);
    setPartnerFilter([]);
    setCurrentDialog(null);
  };

  function handleFilterDelete({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) {
    if (field === 'userType') {
      setUserTypeFilter([]);
    } else if (field === 'userState') {
      setUserStateFilter([]);
    } else if (field === 'partner') {
      setPartnerFilter([]);
    }
  }

  const userTypeFilterSelections =
    userTypeFilter.length > 0
      ? {
          userType: [
            {
              key: userTypeFilter[0],
              value: userTypeFilter[0],
              label: userTypeFilter[0],
              pillLabel: userTypeFilter[0],
            },
          ],
        }
      : null;

  const userStateFilterSelections =
    userStateFilter.length > 0
      ? {
          userState: [
            {
              key: userStateFilter[0],
              value: userStateFilter[0],
              label: userStateFilter[0],
              pillLabel: userStateFilter[0],
            },
          ],
        }
      : null;

  const partnerFilterSelections =
    partnerFilter.length > 0
      ? {
          partner: [
            {
              key: partnerFilter[0],
              value: partnerFilter[0],
              label: partnerFilter[0],
              pillLabel: partnerFilter[0],
            },
          ],
        }
      : null;

  const filters = {
    ...userTypeFilterSelections,
    ...userStateFilterSelections,
    ...partnerFilterSelections,
  };
  // #endregion 5

  if (
    isLoadingPartnerInfo ||
    siteAdminGetUsersIsLoading ||
    isLoadingPartners ||
    oemGetUsersIsLoading || 
    cpoGetUsersIsLoading ||
    moGetUsersIsLoading
  ) {
    return <Loading />;
  }

  if (user){
  if (isOEM || isCPO || isMO) {
    return (
      <>
        <ManageUsers
          isSite={isSite}
          users={users}
          columns={adminColumns}
          userTypeOptions={userTypeOptions.find(element => element.includes(user?.userType)) || []}
          partnerFilter={partnerFilter}
          partners={partners?.partnerList || []}
          formik={formik}
          addUserFormik={adminAddUserFormik}
          editUserFormik={editUserFormik}
          userTypeFilter={userTypeFilter}
          userStateFilter={userStateFilter}
          resetFilters={resetFilters}
          filters={filters}
          onFilterClick={handleFilterDelete}
          addUserMutationIsLoading={oemAdminCreateUserMutation.isLoading || cpoAdminCreateUserMutation.isLoading || moAdminCreateUserMutation.isLoading}
          editUserMutationIsLoading={updateUserMutation.isLoading}
          currentDialog={currentDialog}
          toggleFilterDialog={toggleFilterDialog}
          toggleAddUserDialog={toggleAddUserDialog}
          toggleEditUserDialog={toggleEditUserDialog}
        />
      </>
    );
  }

  return (
    <>
      <ManageUsers
        isSite={isSite}
        users={users}
        columns={adminColumns}
        userTypeOptions={USER_TYPE_LIST}
        formik={formik}
        partnerFilter={partnerFilter}
        partners={partners?.partnerList || []}
        addUserFormik={siteAdminAddUserFormik}
        editUserFormik={editUserFormik}
        userTypeFilter={userTypeFilter}
        userStateFilter={userStateFilter}
        resetFilters={resetFilters}
        filters={filters}
        onFilterClick={handleFilterDelete}
        addUserMutationIsLoading={siteAdminCreateUserMutation.isLoading}
        editUserMutationIsLoading={updateUserMutation.isLoading}
        currentDialog={currentDialog}
        toggleFilterDialog={toggleFilterDialog}
        toggleAddUserDialog={toggleAddUserDialog}
        toggleEditUserDialog={toggleEditUserDialog}
      />
    </>
  );
  }
};
