import React, { useState, createContext } from 'react';
import { languageOptions, dictionaryList } from './languages';
import { getUIStringTableForLanguage } from './db-lib';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  userLanguageChange: (newLang) => {},
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState(getDefaultLanguage());

  async function handleChangeLanguage(newLang) {
    document.querySelector('.l-container').classList.add('u-blur');
    document.querySelector('.spinner-blur').classList.remove('u-is-hidden');
    const newLanguage = languageOptions[newLang]
      ? newLang
      : getDefaultLanguage();
    getUIStringTableForLanguage(newLang, true).then((dictionary) => {
      window.localStorage.setItem('rcml-lang-dict', JSON.stringify(dictionary));
      window.localStorage.setItem('rcml-lang', newLang);
      setUserLanguage(newLanguage);
      document.querySelector('.spinner-blur').classList.add('u-is-hidden');
      document.querySelector('.l-container').classList.remove('u-blur');
    });
  }

  const provider = {
    userLanguage,
    dictionary:
      JSON.parse(window.localStorage.getItem('rcml-lang-dict', userLanguage)) ||
      dictionaryList['en'],
    userLanguageChange: handleChangeLanguage,
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

function getDefaultLanguage() {
  let defaultLanguage = window.localStorage.getItem('rcml-lang');
  if (!defaultLanguage) {
    defaultLanguage = window.navigator.language.substring(0, 2);
    window.localStorage.setItem('rcml-lang', defaultLanguage);
  }
  return defaultLanguage;
}

export function useLanguage() {
  return React.useContext(LanguageContext);
}
