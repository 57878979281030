import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'react-table/react-table.css';
import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import App from './App';
import { ToastContainer } from 'react-toastify';
import registerServiceWorker from './registerServiceWorker';
import config from './config';
import { createBrowserHistory } from 'history';

import { LanguageProvider } from './libs/Language';
import { MenuProvider } from './context/Menu';
import { UserProvider } from './context/User';
import { ConfirmProvider } from './context/Confirm';
import { QueryWrapper } from './QueryWrapper';
import { MapProvider } from './context/Map';

const history = createBrowserHistory();

// Sentry.init({
//   dsn: 'https://d0702877af2d4af1b2c8e2fa323078e5@o1009869.ingest.sentry.io/5974220',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'echo-dev',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'authdiag-dev',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <>
    <link
      href="https://api.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.css"
      rel="stylesheet"
    />
    <LanguageProvider>
      <UserProvider>
        <Router history={history}>
          <MenuProvider>
            <MapProvider>
              <ConfirmProvider>
                <QueryWrapper>
                  <App />
                </QueryWrapper>
              </ConfirmProvider>
            </MapProvider>
          </MenuProvider>
        </Router>
      </UserProvider>
    </LanguageProvider>
    <ToastContainer
      enableMultiContainer
      containerId={'standard'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
    <ToastContainer
      className={'wide-toast'}
      enableMultiContainer
      containerId={'wide'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
  </>,
  document.getElementById('root')
);
registerServiceWorker();
