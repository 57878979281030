import { Auth } from 'aws-amplify';
import React, { useState, FormEvent, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { TextOnly, Text } from '../components/Text';
import { useUser } from '../context/User';
import { updateUser } from '../libs/db-lib';
import { nameValidate, isValidEmail, passwordValidate } from '../libs/utils';

export const UserProfile = () => {
  
  const { user, fetchUser } = useUser();
  const [isLoading,           setIsLoading]           = useState('');

  // Variables & Functions for User Details Update Form
  const [firstName,           setFirstName]           = useState(user?.firstName || '');
  const [lastName,            setLastName]            = useState(user?.lastName || '');
  const changeFirstName = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setFirstName(event.target.value)
  }
  const changeLastName = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setLastName(event.target.value)
  }

  // Variables & Functions for User Email Update Form
  const [userEmail,           setUserEmail]           = useState(user?.email || '');
  const changeUserEmail = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setUserEmail(event.target.value)
  }

  // Variables & Functions for User Confirmation Code Form
  const [userConfirmCode,           setUserConfirmCode]           = useState('');
  const changeUserConfirmCode = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setUserConfirmCode(event.target.value)
  }

  // Variables & Functions for User Password Update Form
  const [userOldPassword,        setUserOldPassword]        = useState('');
  const [userNewPassword,        setUserNewPassword]        = useState('');
  const [userConfirmPassword,        setUserConfirmPassword]        = useState('');
  const changeUserOldPassword = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setUserOldPassword(event.target.value)
  }
  const changeUserNewPassword = (event: ChangeEvent<HTMLInputElement>) =>{
    if(event.target.value.length<51) setUserNewPassword(event.target.value)
  }
  

  const handleSubmitDetails = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading('details');
    
    if (!firstName || !lastName || !user?.email || !user?.userID || !nameValidate(firstName) || !nameValidate(lastName)) {
      toast.error(TextOnly('enterValidName'), {
        containerId: 'standard',
      });
      return setIsLoading('');
    }
    
    const response = await updateUser({
      firstName,
      lastName,
      email: user.email,
      userID: user.userID,      
    });
      
    if (!response.error) {
      console.log("Response success",)
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      await fetchUser();
    } else {
      console.log("Response Error", response.error)
      toast.error(TextOnly('updateError'), {
        autoClose: false,
        containerId: 'standard',
      });
    }

    return setIsLoading('');
  };

  const handleSubmitEmail = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading('email');
    
    if (!userEmail || !user?.firstName || !user?.lastName || !user?.userID || !isValidEmail(userEmail)) {
      toast.error(TextOnly('enterValidEmail'), {
        containerId: 'standard',
      });
      return setIsLoading('');
    }
    
    const response = await updateUser({
      firstName: user.firstName,
      lastName: user.lastName,
      email: userEmail,
      userID: user.userID,      
    });
      
    if (!response.error) {
      console.log("Response success",)
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      await fetchUser();
    } else {
      console.log("Response Error", response.error)
      toast.error(TextOnly('updateError'), {
        autoClose: false,
        containerId: 'standard',
      });
    }
    return setIsLoading('');
  };

  const handleConfirmCode = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading('code');

    Auth.verifyCurrentUserAttributeSubmit('email', userConfirmCode)
      .then(() => {
        toast.success(TextOnly('accountValidationSuccess'), {
          containerId: 'standard',
        });
        fetchUser();
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      }
    );
    
    setUserConfirmCode('')
    return setIsLoading('');
  };

  const handlePasswordChangeSubmit = async (
    password: string,
    newPassword: string
  ) => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, password, newPassword);
      toast.success(TextOnly('passwordUpdated'), {
        containerId: 'standard',
      });
      setUserOldPassword('');
      setUserNewPassword('');
      setUserConfirmPassword('');
    } catch (error: any) {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    }
  }; 

  const handleSubmitPassword = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading('password');
    
    if (!userOldPassword.length || !userNewPassword.length || !(passwordValidate(userNewPassword))) {
      toast.error('', {
        autoClose: false,
        containerId: 'standard',
      });
      return setIsLoading('')
    }

    await handlePasswordChangeSubmit(userOldPassword, userNewPassword);
    return setIsLoading('');
  };

  function handleResendCode() {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        toast.success(TextOnly('codeSent'), {
          containerId: 'standard',
        });
        fetchUser();
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      });
  }

  return (
    <>
      <Header title={TextOnly('userProfile')} />
      <div className="l-view-layout--user-profile">
        <div className="user-profile-form-column">
          <form onSubmit={handleSubmitDetails}>
            <div>
              <h2>
                <Text tid="userDetails" />
              </h2>
              <label className="u-margin-bottom-large">
                <Text tid="usernameLabel" />: {user?.userName}
              </label>
            </div>
            <div className="c-field">
              <label htmlFor="firstName" className="c-field__label">
                <Text tid="firstName" />:
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('firstName')}
                value={firstName}
                onChange={changeFirstName}
              />
            </div>
            <div className="c-field">
              <label htmlFor="lastName" className="c-field__label">
                <Text tid="lastName" />:
              </label>
              <input
                id="lastName"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('lastName')}
                value={lastName}
                onChange={changeLastName}
              />
            </div>
            <LoaderButton
              type="submit"
              id="submit-user-details"
              disabled={!!isLoading || (user?.firstName === firstName && user?.lastName === lastName)}
              isLoading={isLoading==='details'}
              text={TextOnly('update')}
              loadingText={TextOnly('updating')}
            />
          </form>
        </div>

        <div className="user-profile-form-column">
          <form onSubmit={handleSubmitEmail}>
            <div>
              <h2>
                <Text tid="changeEmail" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="email" className="c-field__label">
                <Text tid="email" />:
              </label>
              <input
                id="email"
                name="email"
                type="email"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('email')}
                value={userEmail}
                onChange={changeUserEmail}
              />
            </div>
              <LoaderButton
                type="submit"
                id="submit-email-button"
                disabled={!!isLoading || user?.email === userEmail }
                isLoading={isLoading==='email'}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
          </form>

          {!user?.emailVerified ? (
            <form onSubmit={handleConfirmCode}>
              <div className="c-field">
                <p className="u-margin-bottom-large">
                  <Text tid="accountValidationCodeSent" />
                </p>
                <label htmlFor="confirmCode" className="c-field__label">
                  <Text tid="confirmationCode" />:
                </label>
                <input
                  id="confirmCode"
                  name="confirmCode"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('confirmationCode')}
                  value={userConfirmCode}
                  onChange={changeUserConfirmCode}
                />
              </div>
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!!isLoading}
                isLoading={isLoading==='code'}
                text={TextOnly('submitConfirmationCode')}
                loadingText={TextOnly('confirming')}
              />

              <button
                type="button"
                className="c-btn-link-bare u-margin-top-small"
                onClick={handleResendCode}
              >
                <div className="c-btn__inner">
                  {TextOnly('resendConfirmCode')}
                </div>
              </button>
            </form>
          ) : null}
        </div>

        <div className="user-profile-form-column">
          <form onSubmit={handleSubmitPassword}>
            <div>
              <h2>
                <Text tid="changePassword" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="password" className="c-field__label">
                <Text tid="password" />:
              </label>
              <input
                id="password"
                name="password"
                type="password"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('password')}
                value={userOldPassword}
                onChange={changeUserOldPassword}
              />
            </div>
            <div className="c-field">
              <label htmlFor="newPassword" className="c-field__label">
                <Text tid="newPassword" />:
              </label>
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('newPassword')}
                value={userNewPassword}
                onChange={changeUserNewPassword}
              />
            </div>
            <div className="c-field">
              <label htmlFor="confirmPassword" className="c-field__label">
                <Text tid="confirmPassword" />:
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('confirmPassword')}
                value={userConfirmPassword}
                onChange={e=>setUserConfirmPassword(e.target.value)}
              />
            </div>
            <LoaderButton
              type="submit"
              id="submit-button"
              disabled={ !!isLoading || 
                !userOldPassword.length || 
                !userNewPassword.length ||
                !userConfirmPassword.length ||
                userNewPassword !== userConfirmPassword ||
                userOldPassword === userNewPassword
              }
              isLoading={isLoading==='password'}
              text={TextOnly('changePassword')}
              loadingText={TextOnly('updating')}
            />
          </form>
        </div>
      </div>
    </>
  );
};
