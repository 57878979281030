import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../LoaderButton';
import { useUser } from '../../context/User';
import { Header } from '../Header';
import { TextOnly, Text } from '../Text';
import ReactTable from '../ReactTable';
import { Loading } from '../Loading';
import { 
    getOEMClientEnrollmentList,     getCPOClientEnrollmentList,     getMOClientEnrollmentList,
    requestOEMClientEnrollmentCode, requestCPOClientEnrollmentCode, requestMOClientEnrollmentCode,
    deleteOEMClientEnrollment,      deleteCPOClientEnrollment,      deleteMOClientEnrollment,
    updateOEMClientEnrollment,      updateCPOClientEnrollment,      updateMOClientEnrollment,
} from '../../libs/db-lib';

interface APIClientProps {
}

export const APIClient = () =>{
    // #region State
    const [clientLabel, setClientLabel] = useState<string>('');
    const [clientState, setClientState] = useState<string>('');
    const [enrollmentCode, setEnrollmentCode]  = useState<string>('');
    const [isEnrolled, setIsEnrolled]  = useState<boolean>(false); 
    const [isLoading, setIsLoading] = useState<boolean>(false);    
    const [showDialog, setShowDialog] = useState<string>('');    
    const [search, setSearch] = useState<string>('');
    // #endregion
    
    // #region Queries
    const { isOEM, isCPO, isMO, partnerID } = useUser();
    const { 
            data: enrollmentList, 
            refetch: getEnrollmentList, 
            isLoading: isLoadingGetClientEnrollmentList,
    } = useQuery(
        'getClientEnrollmentList',
        () => {
            if(isOEM) return getOEMClientEnrollmentList({partnerID});
            if(isCPO) return getCPOClientEnrollmentList({partnerID});
            if(isMO) return getMOClientEnrollmentList({partnerID});
            return []
        },
        {
            enabled: (isOEM || isCPO || isMO || !!partnerID),
        }
    );
    const { 
            refetch: createEnrollmentLabel
    } = useQuery(
        'createEnrollmentLabel',
        () => {
            if(isOEM) return requestOEMClientEnrollmentCode({clientLabel});
            if(isCPO) return requestCPOClientEnrollmentCode({clientLabel});
            if(isMO) return requestMOClientEnrollmentCode({clientLabel});
            return null
        },
        {
            enabled: false,
        }
    );
    // #endregion

    // #region HandleFunctions
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };
    const handleCancelDialog = () => {
        setShowDialog('');
        setClientLabel('');
        setEnrollmentCode('');
        setClientState('');
        setIsLoading(false);
        setIsEnrolled(false);
    }
    const handleOnSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsLoading(true);
        await createEnrollmentLabel();
        handleCancelDialog();
        getEnrollmentList();
    }
    const handleUpdate = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsLoading(true);

        if(isOEM) await updateOEMClientEnrollment({enrollmentCode, clientLabel, clientState});
        if(isCPO) await updateCPOClientEnrollment({enrollmentCode, clientLabel, clientState});
        if(isMO) await  updateMOClientEnrollment({enrollmentCode, clientLabel, clientState});

        handleCancelDialog();
        getEnrollmentList();
    }
    const handleDelete = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsLoading(true);
        // let response; //response = 

        if(isOEM) await deleteOEMClientEnrollment({enrollmentCode});
        if(isCPO) await deleteCPOClientEnrollment({enrollmentCode});
        if(isMO) await  deleteMOClientEnrollment({enrollmentCode});

        // TODO: CHECK response IF ERROR

        handleCancelDialog();
        getEnrollmentList();
    }
    //#endregion

    // #region Variables
    const columns = [
        {
            Header: 'Enrollment Code',
            accessor: 'enrollmentCode',
        },
        {
            Header: 'Label',
            accessor: 'clientLabel',
        },
        {
            Header: 'State',
            accessor: 'clientState',
            maxWidth: 40,
        },
        {
            Header: 'Created On',
            accessor: 'createdOn',
            maxWidth: 40,
            Cell: ({ row: { original } }: any) => {
                return <span
                            title={original.createdOn.split('T')[1]}
                        >
                            {original.createdOn.split('T')[0]}
                        </span>
            },
        },
        {
            Header: 'Enrolled',
            accessor: 'enrollmentComplete',
            maxWidth: 40,
            Cell: ({ row: { original } }: any) => {
                return <span>{TextOnly(`${original.enrollmentComplete as boolean}`)}</span>
            },
        },
        {
            Header: 'Redeemed On',
            accessor: 'redeemedOn',
            maxWidth: 40,
            Cell: ({ row: { original } }: any) => {
                return <span
                            title={original.redeemedOn?.split('T')[1]}
                        >
                            {original.redeemedOn?.split('T')[0]}
                        </span> 
            },
        },
        {
            Header: 'Source IP',
            accessor: 'sourceIP',
            maxWidth: 50,
            Cell: ({ row: { original } }: any) => {
                return <span>{original.sourceIP ? original.sourceIP : TextOnly('notApplicable')}</span>
            },
        },
        {
            Header: 'Actions',
            accessor: 'clientActions',
            maxWidth: 40,
            Cell: ({ row: { original } }: any) => {
                
                return <div className='l-flex-gap-1'>
                            {original.enrollmentComplete ?                            
                                <button
                                    className="c-btn-icon-compact"
                                    onClick={()=>{
                                        setClientState(original.clientState);
                                        setEnrollmentCode(original.enrollmentCode);
                                        setClientLabel(original.clientLabel);
                                        setShowDialog('Slider-Update');
                                    }}
                                >   
                                    <div className="c-btn__inner">
                                        <i className="c-btn__icon fa fa-shield-check" />
                                    </div>
                                </button>
                                :
                                <button
                                className="c-btn-icon-compact visibility-hidden"
                                >   
                                    <div className="c-btn__inner">
                                        <i className="c-btn__icon fa fa-shield-check" />
                                    </div>
                                </button>
                            }
                            <button
                                className="c-btn-icon-compact"
                                title={TextOnly('deleteClientEnrollment')}
                                onClick={()=>{
                                    setIsEnrolled(original.enrollmentComplete);
                                    setEnrollmentCode(original.enrollmentCode);
                                    setClientLabel(original.clientLabel);
                                    setShowDialog('Modal');
                                }}
                                    >
                                <div className="c-btn__inner">
                                    <i className="c-btn__icon fa fa-trash" />
                                </div>
                            </button>
                        </div>
            },
        },
    ];
    // #endregion

    if (isLoadingGetClientEnrollmentList) {
        return <Loading />;
    }
    return (
        <>
            <Header title={TextOnly('apiClientEnrollments')} />
            <div className="l-container">
                <div className="l-flex-wrap margin-5px">
                    <div className="l-flex-wrap">
                        <div className="u-margin-right">
                            <button className="c-btn" onClick={()=>setShowDialog('Slider')}>
                                <div className="c-btn__inner">
                                    <i className="c-btn__icon fal fa-plus" />
                                    <Text tid="requestEnrollmentCode" />
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="l-flex-between">
                        <div className="c-field u-margin-bottom-none">
                            <label htmlFor="search" className="c-field__label u-is-vishidden">
                                <Text tid="search" />
                            </label>
                            <input
                                type="text"
                                id="search"
                                maxLength={50}
                                className="c-input"
                                placeholder={TextOnly('search')}
                                value={search}
                                onChange={handleChange}
                            />
                            <i className="c-field__input-icon fal fa-search" />
                        </div>
                    </div>
                </div>
                <div className="u-margin-top-large">
                    <ReactTable
                        columns={columns}
                        data={enrollmentList}
                        globalFilter={search}
                        filter={[]}
                        tableName="apiClientEnrollments"
                    />
                </div>
            </div>
            <Dialog
                isOpen={showDialog==='Slider'}
                onDismiss={handleCancelDialog}
                className="c-modal-slider"
                aria-label={TextOnly('clientLabel')}
            >
                <button
                className="c-btn-icon c-modal-slider__close"
                onClick={handleCancelDialog}
                type="button"
                >
                    <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-times" />
                    </div>
                </button>
                <h1 className="c-modal__heading">
                    <Text tid="requestEnrollmentCode" />
                </h1>
                <div className="c-modal__body">
                    <form onSubmit={handleOnSubmit}>
                        <div className="l-container-sm">
                            <div className="c-field">
                                <label htmlFor="clientLabel" className="c-field__label">
                                    <Text tid="clientLabel" />
                                </label>
                                <input
                                    id="clientLabel"
                                    name="clientLabel"
                                    type="text"
                                    maxLength={20}
                                    placeholder={TextOnly('clientLabel')}
                                    value={clientLabel}
                                    onChange={(e)=>setClientLabel(e.target.value)}
                                />
                            </div>
                            <div className="c-field l-flex-between u-margin-top-xlarge">
                                <button
                                    className="c-btn-outline u-margin-right"
                                    type="button"
                                    onClick={handleCancelDialog}
                                >
                                    {TextOnly('cancel')}
                                </button>

                                <LoaderButton
                                    type="submit"
                                    id="submit-button"
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    text={TextOnly('createLabel')}
                                    loadingText={TextOnly('loading')}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog
                isOpen={showDialog==='Modal'}
                onDismiss={handleCancelDialog}
                className="c-modal"
                aria-label={isEnrolled ? TextOnly('confirmRemoveClientAPI') : TextOnly('confirmRemoveCode')}
            >
                <button
                    className="c-btn-icon c-modal__close"
                    onClick={handleCancelDialog}
                    type="button"
                >
                    <div className="c-btn__inner">
                        <i className="c-btn__icon fa fa-times" />
                    </div>
                </button>
                <h1 className="c-modal__heading">
                    {isEnrolled ? <Text tid="confirmRemoveClientAPI" /> : <Text tid="confirmRemoveCode" /> }
                </h1>

                <div className="c-modal__body">
                    <p>{isEnrolled ?<Text tid="areYouSureRemoveClientAPI" /> : <Text tid="areYouSureRemoveCode" /> }</p>
                    <div>
                        <h2 className='display-inline'>Code: </h2><b>{enrollmentCode}</b>
                    </div>
                    <div>
                        <h2 className='display-inline'>Label: </h2><b>{clientLabel}</b>
                    </div>
                    <form onSubmit={handleDelete}>
                        <div className="c-field l-flex-between u-margin-top-xlarge">
                            <button
                                className="c-btn-outline u-margin-right"
                                type="button"
                                onClick={handleCancelDialog}
                            >
                                {TextOnly('cancel')}
                            </button>
                            <LoaderButton
                                type="submit"
                                id="submit-button"
                                disabled={isLoading}
                                isLoading={isLoading}
                                text={TextOnly('confirm')}
                                loadingText={TextOnly('loading')}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog
                isOpen={showDialog==='Slider-Update'}
                onDismiss={handleCancelDialog}
                className="c-modal-slider"
                aria-label={TextOnly('updateClientEnrollment')}
            >
                <button
                className="c-btn-icon c-modal-slider__close"
                onClick={handleCancelDialog}
                type="button"
                >
                    <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-times" />
                    </div>
                </button>
                <h1 className="c-modal__heading">
                    <Text tid="updateClientEnrollment" />
                </h1>
                <div className="c-modal__body">
                    <form onSubmit={handleUpdate}>
                        <div className="l-container-sm">
                            <div className="c-field">
                                <label htmlFor="enrollmentCode" className="c-field__label">
                                    <Text tid="enrollmentCode" />
                                </label>
                                <input
                                    id="enrollmentCode"
                                    name="enrollmentCode"
                                    type="text"
                                    placeholder={TextOnly('enrollmentCode')}
                                    value={enrollmentCode}
                                    readOnly
                                />
                            </div>
                            <div className="c-field">
                                <label htmlFor="clientLabel" className="c-field__label">
                                    <Text tid="clientLabel" />
                                </label>
                                <input
                                    id="clientLabel"
                                    name="clientLabel"
                                    type="text"
                                    maxLength={20}
                                    placeholder={TextOnly('clientLabel')}
                                    value={clientLabel}
                                    readOnly
                                />
                            </div>
                            <div className="c-field">
                                <label htmlFor="clientState" className="c-field__label">
                                    <Text tid="clientState" />
                                </label>
                                <div className="c-select">
                                    <select 
                                        defaultValue={clientState}
                                        onChange={(e)=>setClientState(e.target.value)}
                                        >
                                        <option value={'ACTIVE'}>ACTIVE</option>
                                        <option value={'INACTIVE'}>INACTIVE</option>
                                    </select>
                                </div>
                            </div>
                            <div className="c-field l-flex-between u-margin-top-xlarge">
                                <button
                                    className="c-btn-outline u-margin-right"
                                    type="button"
                                    onClick={handleCancelDialog}
                                >
                                    {TextOnly('cancel')}
                                </button>

                                <LoaderButton
                                    type="submit"
                                    id="submit-button"
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                    text={TextOnly('update')}
                                    loadingText={TextOnly('loading')}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
}