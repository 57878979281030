import { Sidebar } from './Sidebar';
import React from 'react';
import { Dialog } from '@reach/dialog';
import { useMenu } from '../context/Menu';
import { USER_TYPE } from '../CONSTANTS';

type MobileSidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  userName: string;
  shopName: string;
  userType: USER_TYPE;
};

export function MobileSidebar({
  isAuthenticated,
  logout,
  userName,
  shopName,
  userType,
}: MobileSidebarProps) {
  const { showMobileNav, toggleShowMobileNav } = useMenu();
  return (
    <Dialog
      isOpen={showMobileNav}
      onDismiss={toggleShowMobileNav}
      className="c-modal-menu c-modal-menu-slider"
      aria-label="mobile menu"
    >
      <Sidebar
        userName={userName}
        shopName={shopName}
        userType={userType}
        isAuthenticated={isAuthenticated}
        logout={() => {
          logout();
          toggleShowMobileNav();
        }}
        toggleShowMobileNav={toggleShowMobileNav}
      />
    </Dialog>
  );
}
