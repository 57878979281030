import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { Loading } from '../components/Loading';
import { TextOnly, Text } from '../components/Text';
import { useUser } from '../context/User';
import {
  getPartnerInfo,
  updatePartnerInfo,
  UpdatePartnerInfoParams,
} from '../libs/db-lib';

export const CompanyProfile = () => {
  const { partnerID, isAdmin } = useUser();

  const { data: partnerInfo, isLoading: isLoadingPartnerInfo } = useQuery(
    'getPartnerInfo',
    () => getPartnerInfo({ partnerID }),
    {
      enabled: !!partnerID,
    }
  );

  const updatePartnerInfoMutation = useMutation<
    { error?: string },
    Error,
    UpdatePartnerInfoParams
  >((partnerFields) => updatePartnerInfo(partnerFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      formik.setSubmitting(false);
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      partnerName: partnerInfo?.partnerName || '',
      partnerState: partnerInfo?.partnerState || '',
      partnerType: partnerInfo?.partnerType || '',
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updatePartnerInfoMutation.mutate({
        partnerName: values.partnerName,
        partnerState: values.partnerState,
        partnerType: values.partnerType,
      });
    },
    enableReinitialize: true,
  });

  if (isLoadingPartnerInfo) {
    return <Loading />;
  }

  return (
    <>
      <Header title={TextOnly('userProfile')} />
      <div className="l-view-layout--user-profile">
        <div className="user-profile-form-column">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <h2>
                <Text tid="companyInfo" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="partnerName" className="c-field__label">
                <Text tid="partnerName" />:
              </label>
              <input
                id="partnerName"
                name="partnerName"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('partnerName')}
                value={formik.values.partnerName}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.partnerName ? (
                <div className="c-field__error">
                  <Text tid="partnerNameHint" />
                </div>
              ) : null}
            </div>

            <div className="c-field">
              <label htmlFor="partnerState" className="c-field__label">
                <Text tid="partnerState" />:
              </label>
              <input
                id="partnerState"
                name="partnerState"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('partnerState')}
                value={formik.values.partnerState}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.partnerState ? (
                <div className="c-field__error">
                  <Text tid="partnerStateHint" />
                </div>
              ) : null}
            </div>

            <div className="c-field">
              <label htmlFor="partnerType" className="c-field__label">
                <Text tid="partnerType" />:
              </label>
              <input
                id="partnerType"
                name="partnerType"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('partnerType')}
                value={formik.values.partnerType}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.partnerType ? (
                <div className="c-field__error">
                  <Text tid="partnerTypeHint" />
                </div>
              ) : null}
            </div>

            {isAdmin ? (
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!formik.isValid}
                isLoading={formik.isSubmitting}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};
