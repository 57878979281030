import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';

import logo from '../assets/images/ca.png';
import { ReactComponent as MyActivityIcon } from '../assets/icons/nav-dashboard.svg';
import { ReactComponent as ManageUsersIcon } from '../assets/icons/icon-nav-oem-manageusers.svg';
import { ReactComponent as ManagePartnersIcon } from '../assets/icons/icon-nav-siteadmin-managepartners.svg';
import { ReactComponent as UserProfileIcon } from '../assets/icons/nav-user-profile.svg';
import { ReactComponent as SiteAdminIcon } from '../assets/icons/icon-nav-siteadmin-userprofile.svg';
import { ReactComponent as RootPool } from '../assets/icons/icon-nav-oem-docs.svg';

import { USER_TYPE } from '../CONSTANTS';
import { useUser } from '../context/User';
import { useMap } from '../context/Map';
import { Text, TextOnly } from '../components/Text';
import { Loading } from '../components/Loading';
import { getPartnerInfo } from '../libs/db-lib';

type SidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  toggleShowMobileNav: () => void;
  userName: string;
  shopName: string;
  userType: USER_TYPE;
};

export function Sidebar({
  isAuthenticated,
  logout,
  toggleShowMobileNav,
  userType,
  userName,
}: SidebarProps) {
  const { isAdmin, isSite, partnerID } = useUser();
  const { mapIsShown } = useMap();
  const { data: partnerInfo, isLoading: isLoadingPartnerInfo } = useQuery(
    'getPartnerInfo',
    () => getPartnerInfo({ partnerID: partnerID }),
    {
      enabled: !!partnerID,
    }
  );
  const partnerName = partnerInfo?.partnerName;
  if (isLoadingPartnerInfo) {
    return <Loading />;
  }

  // IMPROVE not userName??
  if (!isAuthenticated || !userName) {
    return (
      <div className={`c-sidebar ${mapIsShown ? 'c-sidebar--light' : ''}`}>
        <div className="c-sidebar__header c-sidebar__header--empty">
          <NavLink
            className={({ isActive }) =>
              'c-primary-nav__link' + (isActive ? ' is-active' : '')
            }
            to="/"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
          <p className="u-font-weight-500">
            <Text tid="tagLine" />
          </p>
        </div>
      </div>
    );
  }
  
  return(
    <div className={`c-sidebar ${mapIsShown ? 'c-sidebar--light' : ''}`}>
      <div className="c-sidebar__header">
        <button
          className="c-btn-icon c-btn-mobile-close"
          onClick={toggleShowMobileNav}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <NavLink
          className={({ isActive }) =>
            'c-primary-nav__link' + (isActive ? ' is-active' : '')
          }
          to="/myActivity"
          onClick={toggleShowMobileNav}
        >
          <div className="c-logo">
            <img src={logo} className="c-logo__image" alt="logo" />
          </div>
        </NavLink>
      </div>
      <div className="c-sidebar__main">
        <div>
          <nav className="c-primary-nav" role="navigation">
            <ul className="c-primary-nav__list">
              <li className="c-primary-nav__item u-text-transform-initial">
                <NavLink
                  className={({ isActive }) =>
                    'c-primary-nav__link' + (isActive ? ' is-active' : '')
                  }
                  to="/myActivity"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    <MyActivityIcon
                      className="c-primary-nav__icon"
                      title={TextOnly('myActivity')}
                    />                    
                    {TextOnly('myActivity')}
                  </div>
                </NavLink>
              </li>
              <li className="c-primary-nav__item u-text-transform-initial">
                <NavLink
                  className={({ isActive }) =>
                    'c-primary-nav__link' + (isActive ? ' is-active' : '')
                  }
                  to="/rootCertificatePool"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    <RootPool
                      className="c-primary-nav__icon"
                      title={TextOnly('rootCertificatePool')}
                    />                    
                    {TextOnly('rootCertificatePool')}
                  </div>
                </NavLink>
              </li>
              {isAdmin && 
                <>
                <li className="c-primary-nav__item">
                  <NavLink
                    className={({ isActive }) =>
                      'c-primary-nav__link' + (isActive ? ' is-active' : '')
                    }
                    to="/manageUsers"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManageUsersIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('manageUsers')}
                      />
                      <Text tid="manageUsers" />
                    </div>
                  </NavLink>
                </li>
                {isSite &&
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/managePartners"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManagePartnersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('managePartners')}
                        />
                        <Text tid="managePartners" />
                      </div>
                    </NavLink>
                  </li>
                }
                </>
              }
              {(userType && !isSite) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className={({ isActive }) =>
                      'c-primary-nav__link' + (isActive ? ' is-active' : '')
                    }
                    to="/companyProfile"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManagePartnersIcon
                        className="c-primary-nav__icon"
                        title={partnerName}
                      />
                      {partnerName}
                    </div>
                  </NavLink>
                </li>              
              }
              {(!isSite && isAdmin) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className={({ isActive }) =>
                      'c-primary-nav__link' + (isActive ? ' is-active' : '')
                    }
                    to="/APIClient"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManagePartnersIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('apiClient')}
                      />
                      {TextOnly('apiClient')}
                    </div>
                  </NavLink>
                </li>              
              }
              <li className="c-primary-nav__item u-text-transform-initial">
                <NavLink
                  className={({ isActive }) =>
                    'c-primary-nav__link' + (isActive ? ' is-active' : '')
                  }
                  to="/userProfile"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    {isSite ? (
                      <SiteAdminIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('userProfile')}
                      />
                    ) : (
                      <UserProfileIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('userProfile')}
                      />
                    )}
                    
                    {userName}
                  </div>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <ul className="c-sidebar__links">
          <li>
            <a
              className="c-sidebar__link c-btn-light"
              href="https://info.autoauth.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-question-circle" />
                <Text tid="support" />
              </div>
            </a>
          </li>
          <li>
            <button className="c-sidebar__link c-btn-light" onClick={logout}>
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-sign-out" />
                <Text tid="logout" />
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}
