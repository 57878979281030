import { API } from 'aws-amplify';
import { authStorage, trimObjFields } from './utils-ts';
import config from '../config';
import {
  USER_TYPE,
  PARTNER_STATE,
  PARTNER_TYPE,
  CORS_ERROR_MESSAGE,
} from '../CONSTANTS';
import {
  User,
  Partner,
} from '../types';

const APIWrap = {
  ...API,
  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get: (apiName: string, path: string, init: object) =>
    API.get(apiName, path, trimObjFields(init)),
  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post: (apiName: string, path: string, init: object) =>
    API.post(apiName, path, trimObjFields(init)),
};

export interface HttpResponse<T> extends Response {
  error?: T;
  message?: string;
  errorDesc?: string;
}

export interface HttpError extends Error {
  errorDesc?: string;
}

export async function authFetch(endpoint: string, options?: any) {
  const loginTokens = authStorage.getLoginTokens();
  if (!loginTokens.IdToken) {
    throw new Error('Unauthorized');
  }

  return await fetch(endpoint, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: loginTokens.IdToken,
      'Content-Type': 'text/plain',
    },
  })
    .then((response: HttpResponse<string>) => {
      if (response.status === 401) {
        throw CORS_ERROR_MESSAGE;
      } else if (!response.ok && response?.errorDesc) {
        throw new Error(response.errorDesc);
      } else if (!response.ok && response?.message) {
        throw new Error(response.message);
      } else if (!response.ok) {
        throw new Error('Network Error');
      } else {
        return response.json();
      }
    })
    .catch((error: HttpError) => {
      if (error?.errorDesc) {
        throw new Error(error.errorDesc);
      } else if (error?.message) {
        throw new Error(error.message);
      } else {
        throw new Error(error.toString());
      }
    });
}

// SITE_ADMIN function IDs
const SITE_ADM_FUNCTION_CREATE_USER = 1;
const SITE_ADM_FUNCTION_SET_USER_STATE = 2;
const SITE_ADM_FUNCTION_GET_USER_LIST = 3;
// const SITE_ADM_FUNCTION_GET_PARTNER_LIST = 4;
const SITE_ADM_FUNCTION_CREATE_PARTNER = 5;
const SITE_ADM_FUNCTION_SET_USER_TYPE = 6;
const SITE_ADM_FUNCTION_SET_PARTNER_STATE = 7;

// OEM_ADMIN
const OEM_ADM_FUNCTION_CREATE_USER = 1;
const OEM_ADM_FUNCTION_SET_USER_STATE = 2;
const OEM_ADM_FUNCTION_GET_USER_LIST = 3;
const OEM_ADM_FUNCTION_SET_USER_TYPE = 4;
const OEM_ADM_FUNCTION_REQUEST_ENROLLMENT   = 5;
const OEM_ADM_FUNCTION_GET_ENROLLMENT_LIST  = 6;
const OEM_ADM_FUNCTION_UPDATE_ENROLLMENT    = 7;
const OEM_ADM_FUNCTION_DELETE_ENROLLMENT    = 8;

// CPO_ADMIN
const CPO_ADM_FUNCTION_CREATE_USER = 1;
const CPO_ADM_FUNCTION_SET_USER_STATE = 2;
const CPO_ADM_FUNCTION_GET_USER_LIST = 3;
const CPO_ADM_FUNCTION_SET_USER_TYPE = 4;
const CPO_ADM_FUNCTION_REQUEST_ENROLLMENT  = 5;
const CPO_ADM_FUNCTION_GET_ENROLLMENT_LIST = 6;
const CPO_ADM_FUNCTION_UPDATE_ENROLLMENT   = 7;
const CPO_ADM_FUNCTION_DELETE_ENROLLMENT   = 8;

// MO_ADMIN
const MO_ADM_FUNCTION_CREATE_USER = 1;
const MO_ADM_FUNCTION_SET_USER_STATE = 2;
const MO_ADM_FUNCTION_GET_USER_LIST = 3;
const MO_ADM_FUNCTION_SET_USER_TYPE = 4;
const MO_ADM_FUNCTION_REQUEST_ENROLLMENT  = 5;
const MO_ADM_FUNCTION_GET_ENROLLMENT_LIST = 6;
const MO_ADM_FUNCTION_UPDATE_ENROLLMENT   = 7;
const MO_ADM_FUNCTION_DELETE_ENROLLMENT   = 8;


// INTERFACES
//Response
export interface getPartnersResponse {
  partnerList: Partner[];
}
export interface GetUsersResponse {
  userList: User[];
}
export interface CreatePartnerResponse {
  partnerID: string;
  partnerName: string;
  partnerType: PARTNER_TYPE;
  partnerState: PARTNER_STATE;
  createdOn: string;
}
// Params
export interface LoginParams {
  username: string;
  password: string;
}
export interface GetPartnerInfoParams {
  partnerID: string;
}
export interface GetEnrollmentListParams {
  partnerID?: string;
}
export interface UpdatePartnerInfoParams {
  partnerID?: string;
  partnerName: string;
  partnerState: string;
  partnerType: string;
  // partnerContactStreet: string;
  // partnerContactCity: string;
  // partnerContactState: string;
  // partnerContactZip: string;
  // partnerContactPhone: string;
}
export interface UpdateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  userID?: string;
}
export interface CreateUserStateParams {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: USER_TYPE;
  partnerID?: string;
}
export interface SetUserStateParams {
  userID: string;
  userState: string;
}
export interface SetPartnerStateParams {
  partnerID: string;
  partnerState: string;
}
export interface SetUserTypeParams {
  userID: string;
  userType: string;
}
export interface CreatePartnerParams {
  partnerName: string;
  partnerType: PARTNER_TYPE;
}
// export interface CreateOEMUserStateParams {
//   userName: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   password: string;
//   userType: USER_TYPE.OEM_ADMIN | USER_TYPE.OEM_USER;
//   partnerID: string;
// }
// export interface CreateCPOUserStateParams {
//   userName: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   password: string;
//   userType: USER_TYPE.CPO_ADMIN | USER_TYPE.CPO_USER;
//   partnerID: string;
// }
// export interface CreateMOUserStateParams {
//   userName: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   password: string;
//   userType: USER_TYPE.MO_ADMIN | USER_TYPE.MO_USER;
//   partnerID: string;
// }


export async function getUIStringTableForLanguage(
  language: string,
  forFrontEnd: boolean
) {
  return await APIWrap.get(
    'authdiag-dev',
    '/ui/getUIStringTableForLanguage?languageID=' +
      language +
      '&forFrontEnd=' +
      forFrontEnd,
    { response: true }
  )
    .then((result) => {
      return result['data'];
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response.data.errorDesc };
      } else {
        return { error: error };
      }
    });
}
export async function login({ username, password }: LoginParams) {
  return await fetch(config.apiGateway.URL + '/partnerui/login', {
    body: JSON.stringify({ username, password }),
    method: 'POST',
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response.errorDesc) {
        return { error: response.errorDesc };
      } else {
        return response;
      }
    })
    .catch((error) => {
      if (error.errorDesc) {
        return { error: error.errorDesc };
      } else {
        return { error: error };
      }
    });
}
export async function refreshToken() {
  const RefreshToken = authStorage.getLoginTokens()?.RefreshToken;

  return await fetch(config.apiGateway.URL + '/partnerui/refresh', {
    body: JSON.stringify({
      RefreshToken: RefreshToken,
    }),
    method: 'POST',
  })
    .then((result) => {
      return result.json();
    })
    // .then((response) => {
    //   if (response.errorDesc) {
    //     return { error: response.errorDesc };
    //   } else {
    //     return response;
    //   }
    // })
    .catch((error) => {
      if (error.errorDesc) {
        return { error: error.errorDesc };
      } else {
        return { error: error };
      }
    });
}
export async function getUserInfo() {
  return await authFetch(config.apiGateway.URL + '/partnerui/getUserInfo', {
    method: 'POST',
  });
}
export async function getRCPList() {
  return await authFetch(config.apiGateway.URL + '/partnerui/getRCPList', {
    method: 'POST',
  });
}
export async function getPartnerInfo({
  partnerID,
}: GetPartnerInfoParams): Promise<Partner> {
  return await authFetch(config.apiGateway.URL + '/partnerui/getPartnerInfo', {
    method: 'POST',
    body: JSON.stringify({
      partnerID,
    }),
  });
}
export async function updatePartnerInfo({
  partnerID,
  partnerName,
  partnerState,
  partnerType,
}: UpdatePartnerInfoParams): Promise<HttpResponse<string>> {
  return await authFetch(config.apiGateway.URL + '/partnerui/updatePartnerInfo', {
    method: 'POST',
    body: JSON.stringify({
      partnerID,
      partnerName,
      partnerState,
      partnerType,
    }),
  });
}
export async function getPartners(): Promise<getPartnersResponse> {
  return await authFetch(config.apiGateway.URL + '/partnerui/getPartnerList', {
    method: 'POST',
  });
}
export async function getSystemConfig() {
  return await fetch(config.apiGateway.URL + '/ui/getUIConfigs', {
    // @ts-ignore - TODO fix fetch type for system config
    response: true,
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response.errorDesc) {
        return response.errorDesc;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}
export async function updateUser({
  firstName,
  lastName,
  email,
  userID,
}: UpdateUserParams): Promise<HttpResponse<string>> {
  return await authFetch(config.apiGateway.URL + '/partnerui/updateUser', {
    method: 'POST',
    body: JSON.stringify({
      userID,
      firstName,
      lastName,
      email,
    }),
  });
}


export async function siteAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}
export async function siteAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  partnerID,
}: CreateUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        partnerID,
      }),
    }
  );
}
export async function siteAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}
export async function siteAdminSetPartnerState({
  partnerID,
  partnerState,
}: SetPartnerStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_PARTNER_STATE,
        partnerID,
        partnerState,
      }),
    }
  );
}
export async function siteAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}
export async function siteAdminCreatePartner({
  partnerName,
  partnerType,
}: CreatePartnerParams): Promise<CreatePartnerResponse> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_CREATE_PARTNER,
        partnerName,
        partnerType,
      }),
    }
  );
}


export async function oemAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}
export async function oemAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  partnerID,
}: CreateUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        partnerID,
      }),
    }
  );
}
export async function oemAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}
export async function oemAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}
export async function requestOEMClientEnrollmentCode({
    clientLabel,
}: {clientLabel: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execOemAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        clientLabel,
        functionID: OEM_ADM_FUNCTION_REQUEST_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function getOEMClientEnrollmentList({
  partnerID,
}: GetEnrollmentListParams): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execOemAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        partnerID,
        functionID: OEM_ADM_FUNCTION_GET_ENROLLMENT_LIST,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function updateOEMClientEnrollment({    
    enrollmentCode,
    clientLabel,
    clientState,
}: {
    enrollmentCode: string,
    clientLabel: string,
    clientState: string,
    }): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execOemAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
            enrollmentCode,
            clientLabel,
            clientState,
            functionID: OEM_ADM_FUNCTION_UPDATE_ENROLLMENT,
        }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function deleteOEMClientEnrollment({
    enrollmentCode,
}: {enrollmentCode: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execOemAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
            enrollmentCode: enrollmentCode,
            functionID: OEM_ADM_FUNCTION_DELETE_ENROLLMENT,
        }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}


export async function cpoAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execCpoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: CPO_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}
export async function cpoAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  partnerID,
}: CreateUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execCpoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: CPO_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        partnerID,
      }),
    }
  );
}
export async function cpoAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execCpoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: CPO_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}
export async function cpoAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execCpoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: CPO_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}
export async function requestCPOClientEnrollmentCode({
    clientLabel,
}: {clientLabel: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execCpoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        clientLabel,
        functionID: CPO_ADM_FUNCTION_REQUEST_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function getCPOClientEnrollmentList({
  partnerID,
}: GetEnrollmentListParams): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execCpoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        partnerID,
        functionID: CPO_ADM_FUNCTION_GET_ENROLLMENT_LIST,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function updateCPOClientEnrollment({    
    enrollmentCode,
    clientLabel,
    clientState,
}: {
    enrollmentCode: string,
    clientLabel: string,
    clientState: string,
    }): Promise<any> {
        const response =  await authFetch(config.apiGateway.URL + '/partnerui/execCpoAdminFunction', {
            method: 'POST',
            body: JSON.stringify({
                enrollmentCode,
                clientLabel,
                clientState,
                functionID: CPO_ADM_FUNCTION_UPDATE_ENROLLMENT,
        })
        })
        .then(result=>{
            return result;
        })
        .catch(error=>{
            return {error};
        })
        return response;
}
export async function deleteCPOClientEnrollment({
    enrollmentCode,
}: {enrollmentCode: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execCpoAdminFunction', {
    method: 'POST',
    body: JSON.stringify({
        enrollmentCode,
        functionID: CPO_ADM_FUNCTION_DELETE_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}


export async function moAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execMoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: MO_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}
export async function moAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  partnerID,
}: CreateUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execMoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: MO_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        partnerID,
      }),
    }
  );
}
export async function moAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execMoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: MO_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}
export async function moAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/partnerui/execMoAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: MO_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}
export async function requestMOClientEnrollmentCode({
    clientLabel,
}: {clientLabel: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execMoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        clientLabel,
        functionID: MO_ADM_FUNCTION_REQUEST_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function getMOClientEnrollmentList({
  partnerID,
}: GetEnrollmentListParams): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execMoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        partnerID,
        functionID: MO_ADM_FUNCTION_GET_ENROLLMENT_LIST,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function updateMOClientEnrollment({    
    enrollmentCode,
    clientLabel,
    clientState,
}: {
    enrollmentCode: string,
    clientLabel: string,
    clientState: string,
    }): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execMoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        enrollmentCode,
        clientLabel,
        clientState,
        functionID: MO_ADM_FUNCTION_UPDATE_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}
export async function deleteMOClientEnrollment({
    enrollmentCode,
}: {enrollmentCode: string}): Promise<any> {
    const response = await authFetch(config.apiGateway.URL + '/partnerui/execMoAdminFunction', {
        method: 'POST',
        body: JSON.stringify({
        enrollmentCode,
        functionID: MO_ADM_FUNCTION_DELETE_ENROLLMENT,
    }),
    })
    .then(result=>{
        return result;
    })
    .catch(error=>{
        return {error};
    })
    return response;
}