import React, { useMemo, useState, useEffect } from 'react';
import {
  FilterValue,
  Row,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useTable,
  useSortBy,
} from 'react-table';
import { TextOnly, Text } from '../Text';
import debounce from 'lodash/debounce';

export type TableColumnsType = {
  Header: string;
  accessor: string;
  filterType?:
    | string
    | ((
        rows: Array<Row>,
        columnIds: Array<String>,
        filterValue: FilterValue
      ) => Row[]);
  Cell?: ({ row: { original } }: any) => JSX.Element;
  disableSortBy?: boolean;
};

interface FilterType {
  columnId: string;
  filter?: string;
}

interface ReactTableProps {
  data: Array<object>;
  columns: Array<TableColumnsType>;
  globalFilter: string;
  tableName: string;
  filter?: Array<FilterType>;
  noDataText?: string;
}

export const ReactTable = ({
  data,
  columns,
  globalFilter,
  filter,
  noDataText,
  tableName,
}: ReactTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const tableData = useMemo(() => data, [data]);

  const savedColumnWidths = useMemo(() => {
    const data = localStorage.getItem(`TABLE_COLS_${tableName}`);
    return data ? JSON.parse(data) : {};
  }, [tableName]);

  const tableColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        // set columns width if in local storage
        width: savedColumnWidths[col.accessor] || defaultColumn.width,
      })),
    [columns,savedColumnWidths,defaultColumn.width]
  );

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetGlobalFilter: false,
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const debouncedUpdateStoredWidths = useMemo(
    () =>
      debounce((columnWidths) => {
        // update if user changes the column widths
        if (Object.keys(columnWidths).length === 0) {
          return;
        }

        if (
          JSON.stringify(savedColumnWidths) !== JSON.stringify(columnWidths)
        ) {
          const data = localStorage.getItem(`TABLE_COLS_${tableName}`);
          const storedColumns = data ? JSON.parse(data) : {};

          localStorage.setItem(
            `TABLE_COLS_${tableName}`,
            JSON.stringify({ ...storedColumns, ...columnWidths })
          );
        }
      }, 700),
    [savedColumnWidths, tableName]
  );

  useEffect(() => {
    const columnWidths = tableInstance.state.columnResizing.columnWidths;
    debouncedUpdateStoredWidths(columnWidths);
  }, [tableInstance.state.columnResizing.columnWidths, debouncedUpdateStoredWidths]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  React.useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter, setGlobalFilter]);

  React.useEffect(() => {
    filter?.forEach((item) => {
      setFilter(item.columnId, item.filter);
    });
  }, [filter, setFilter]);

  React.useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex, setCurrentPage]);

  return (
    <div className="c-react-table">
      <div className="c-react-table__table" {...getTableProps()}>
        <div className="c-react-table__table__thead">
          {headerGroups.map((headerGroup: any) => (
            <div
              className="c-react-table__table__thead__tr"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <div
                  className="c-react-table__table__thead__th"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="c-react-table__table__thead__th__content">
                    {column.render('Header')}
                  </div>
                  {column.canSort && (
                    <div className="c-react-table__table__thead__th__sort">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fad fa-sort-down"></i>
                        ) : (
                          <i className="fad fa-sort-up"></i>
                        )
                      ) : (
                        <i className="fad fa-sort"></i>
                      )}
                    </div>
                  )}
                  <div
                    className="c-react-table__table__thead__th__resizer"
                    {...column.getResizerProps()}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  ></div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="c-react-table__table__tbody" {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row: any) => {
              prepareRow(row);
              return (
                <div
                  key={row.id}
                  className="c-react-table__table__tbody__tr-group"
                >
                  <div
                    className="c-react-table__table__tbody__tr"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <div
                          className="c-react-table__table__tbody__td"
                          {...cell.getCellProps()}
                        >
                          <div className="c-react-table__table__tbody__td__cell">
                            {cell.render('Cell')}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="c-react-table__empty-message">
              {noDataText || <Text tid="noDataToShow" />}
            </p>
          )}
        </div>
      </div>
      <div className="c-react-table__pagination">
        <div className="c-react-table__pagination__previous">
          <button
            className="c-react-table__pagination__btn"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {TextOnly('previous')}
          </button>
        </div>
        <div className="c-react-table__pagination__center">
          <span className="c-react-table__pagination__center__page-info">
            {TextOnly('page')}{' '}
            <div className="c-react-table__pagination__center__page-info__page-jump">
              <input
                type="number"
                value={currentPage + 1}
                onChange={(e) => {
                  // since currentPage also changes on pageIndex change, if a backspace is entered into the input field, it will just go to the previous page, so we need to handle that scenario here
                  if (e.target.value) {
                    setCurrentPage(parseInt(e.target.value) - 1);
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  } else {
                    // if the input is a backspace, we want to trim the last number from the currentPage value. We don't want to change pages until a value is entered
                    const currPageStr = currentPage
                      .toString()
                      .substring(0, currentPage.toString().length - 1);
                    setCurrentPage(parseInt(currPageStr));
                  }
                }}
              />{' '}
              of <strong>{pageOptions.length}</strong>
            </div>
          </span>
          <span className="c-react-table__pagination__center__page-info">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} {TextOnly('rows')}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="c-react-table__pagination__next">
          <button
            className="c-react-table__pagination__btn"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {TextOnly('next')}
          </button>
        </div>
      </div>
    </div>
  );
};
