import React, { useCallback, useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../context/User';
import { ChildProps } from '../../types';
import { Loading } from '../Loading';
import { TextOnly } from '../Text';
import { authStorage } from '../../libs/utils-ts';
import { refreshToken } from '../../libs/db-lib';
import { checkPermission } from './AuthenticatedRoute.utils';
import { CORS_ERROR_MESSAGE } from '../../CONSTANTS';

export interface Permissions {
  roles?: string[];
}

export type ProtectedRouteProps = {
  component: React.Component | any;
  props: ChildProps;
  permissions?: Permissions;
  permissionRedirectPath: string;
} & RouteProps;

export default function AuthenticatedRoute({
  component: C,
  props: cProps,
  permissions,
  permissionRedirectPath,
  ...routeProps
}: ProtectedRouteProps) {
  const location = useLocation();
  const { user, error, updateError } = useUser();

  const hasPermission = user ? checkPermission(user, permissions) : false;

  const tokens = authStorage.getLoginTokens();

  const getNewTokenOnRouteChange = useCallback(async () => {
    const result = await refreshToken();

    if (result?.error) {
      updateError(result.error);
    } else {
      authStorage.setLoginTokens({
        ...tokens,
        IdToken: result?.IdToken,
        ExpiresIn: result?.ExpiresIn,
      });
    }
  }, [updateError, tokens]);

  useEffect(() => {
    if (tokens?.IdToken && tokens?.RefreshToken) {
      getNewTokenOnRouteChange();
    }
  }, [getNewTokenOnRouteChange, tokens]);

  if (error?.error === CORS_ERROR_MESSAGE) {
    toast.error(error?.error || TextOnly('sessionExpired'), {
      containerId: 'standard',
      autoClose: false,
    });
    cProps.logout();
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login.
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!user && tokens.IdToken) {
    return <Loading />;
  } else if (!cProps.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login.
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!hasPermission) {
    toast.info(`${TextOnly('dontHavePermission')}: ${location.pathname}`, {
      containerId: 'standard',
      autoClose: false,
    });
    return <Navigate to={`/`} />;
  } else if (!user) {
    return <Loading />;
  } else {
    return <C {...routeProps} {...cProps} />;
  }
}
