import React from 'react';
import { Dialog } from '@reach/dialog';
import { TextOnly } from './Text';

// cell renderer for the actions column. this is a very basic cell renderer,

type AlertModalProps = {
  showModal: boolean;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  title?: string;
  message: string | React.ReactNode;
  BtnText?: string;
  ConfirmBtnText?: string;
};

const AlertModal = ({
  showModal,
  handleCancel,
  title,
  message,
  BtnText,
  handleConfirm,
  ConfirmBtnText,
}: AlertModalProps) => {
  function handleCancelAlert() {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      if (handleCancel) {
        handleCancel();
      }
    }, 350);
  }

  function handleConfirmAlert() {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      if (handleConfirm) {
        handleConfirm();
      }
    }, 350);
  }

  return (
    <Dialog
      isOpen={showModal}
      onDismiss={handleCancelAlert}
      className="c-modal"
      aria-label={title || 'alert modal'}
    >
      <button className="c-btn-icon c-modal__close" onClick={handleCancelAlert}>
        <div className="c-btn__inner">
          <i className="c-btn__icon fa fa-times" />
        </div>
      </button>
      {title ? <h1 className="c-modal__heading">{title}</h1> : null}

      <div className="c-modal__body">
        <p>{message}</p>
        <div className="l-flex-between">
          <div />
          <div>
            <button
              className="c-btn-outline u-margin-top-large"
              onClick={handleCancelAlert}
            >
              {BtnText ? BtnText : TextOnly('dismiss')}
            </button>
            {handleConfirm ? (
              <button
                className="c-btn u-margin-top-large u-margin-left"
                onClick={handleConfirmAlert}
              >
                {ConfirmBtnText ? ConfirmBtnText : TextOnly('confirm')}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertModal;
