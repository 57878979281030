export const en = {
  // Common
  dismiss: 'Dismiss',
  confirm: 'Confirm',
  dontHavePermission: 'You do not have permission to access this page',
  noDataYet: 'No Data Yet',
  state: 'State',
  support: 'Support',
  cancel: 'Cancel',
  pageNotFound: 'Page Not Found',
  previous: 'Previous',
  next: 'Next',
  page: 'Page',
  rows: 'Rows',
  create: 'Create',
  creating: 'Creating',
  invalidField: '<field> is invalid',
  requiredField: '<field> is required',
  noDataToShow: 'No Data to Show',
  sessionExpired: 'Session Expired',
  contactAA: 'Contact AutoAuth',

  // Login page
  username: 'username',
  password: 'password',
  forgotPassword: 'Forgot Password?',
  resendConfirmLink: 'Resend confirmation link',
  userLogin: 'User Login',
  loggingIn: 'Logging in...',
  loginError: 'Login error',
  login: 'Login',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  faq: 'FAQ',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  refundCancelPolicy: 'Refund/Cancelation Policy',
  systemInfo: 'System Info',
  tagLine:
    'Secure, Seamless Plug & Charge Solution',
  incorrectUserAndPass: 'Incorrect username or password',
  userNotConfirmed: 'User not confirmed',
  changePassword: 'Change Password',
  enterNewPassword: 'Enter new password',
  passwordChanged: 'Password successfully changed',
  passwordRequirements: 'Password requirements',
  usernameAndPasswordRequired: 'Username and password required',
  failedToSendConfirmationCode: 'Failed to send confirmation code',

  // Sidebar
  myActivity: 'My Activity',
  userProfile: 'User Profile',
  manageUsers: 'Manage Users',
  logout: 'Logout',
  shopProfile: 'Shop Profile',
  loading: 'Loading...',
  documentation: 'Documentation',
  viewTools: 'View Tools',
  managePartners: 'Manage Partners',
  apiClient: 'API Client',
  rootCertificatePool: 'Root Certificate Pool',

  // My Activity
  validateEmail: 'Validate Email',
  mustValidateEmailMessage:
    'Your email has changed. Confirm your new email in your Profile.',

// Root Certificate Pool
  info: 'Info',
  download: 'Download',
  rcDownload: 'Root Certificate Download',
  rcinfo: 'Root Certificate Info',
  validFrom: 'Valid From',
  distinguishedName: 'Distinguished Name',
  rootAuthorityKeyIdentifier: 'Root Authority Key Identifier',
  certID: 'Certificate ID',
  commonName: 'Common Name',
  organizationName: 'Organization Name',
  fingerprint: 'Fingerprint',
  rootType: 'Root Type',
  validTo: 'Valid To',
  fileName: 'File Name',

  // Manage Users
  addUsers: 'Add Users',
  filterBy: 'filter by...',
  search: 'search...',
  unknown: 'unknown',
  LINK: 'LINK',
  filters: 'Filters',
  applyFilters: 'Apply Filters',
  role: 'Role',
  partner: 'Partner',
  selectRole: 'Select Role',
  status: 'Status',
  selectStatus: 'Select Status',
  resetFilters: 'Reset Filters',
  siteAdmin: 'Site Admin',
  oemAdmin: 'OEM Admin',
  toolAdmin: 'Tool Admin',
  oemUser: 'OEM User', // Delete ? Is it been used?
  toolUser: 'Tool User',
  active: 'Active',
  inactive: 'Inactive',
  all: 'All',
  editUser: 'Edit User',
  deleteUser: 'Delete User',
  addUser: 'Add User',
  adding: 'Adding...',
  saveChanges: 'Save Changes',
  editUserRole: 'Edit User Role',
  createUserSuccess: 'User created successfully',
  userName: 'User Name',
  userNameHint: 'User name is not valid',
  passwordHint:
    'Password must be at least 8 characters long, contain an upper case and a lower case letter, and at least one number',
  confirmChangeRole: "Change user's role",
  confirmChangeRoleMessage:
    'Are you sure you want to change <userName> to <userType>?',
  userTypeHint: 'User type is required',
  partnerIDHint: 'Partner is required',
  selectPartner: 'Select Partner',
  userNameFormatRequirements:
    'Must start with a letter and can only contain letters, numbers, and periods and must be at least 8 characters long.',
  usernamePlaceholder: 'username',
  firstNamePlaceholder: 'first name',
  lastNamePlaceholder: 'last name',
  emailPlaceholder: 'email',
  userTypePlaceholder: 'Role...',
  oemPartnerPlaceholder: 'OEM Partner...',
  selectPartnerPlaceholder: 'Select Partner...',

  // Manage Partners
  addPartners: 'Add Partners',
  partnerType: 'Partner Type',
  createPartnerSuccess: 'Partner created successfully',
  addPartner: 'Add Partner',
  editPartner: 'Edit Partner',
  selectPartnerType: 'Select Partner Type',

  //API Client
  addAPIClient: 'add API Client',
  false: 'FALSE',
  true: 'TRUE',
  notApplicable: 'N/A',
  apiClientEnrollments: 'API Client Enrollments',
  enrollNewClient: 'New Client Enrollment',
  clientLabel: 'Client Label',
  enrollmentCode: 'Enrollment Code',
  clientState: 'Client State',
  requestEnrollmentCode: 'Request enrollment code',
  createLabel: 'Create Label',
  deleteClientEnrollment: 'Delete Client Enrollment',
  updateClientEnrollment: 'Update Client Enrollment',
  confirmRemoveCode: 'Confirm Remove Enrollment Code',
  confirmRemoveClientAPI: 'Confirm Remove Enrolled Client',
  areYouSureRemoveCode: 'Are you sure you want to remove this Enrollment Code?',
  areYouSureRemoveClientAPI: 'Are you sure you want to remove this Enrolled Client?',

  // View Agreements
  oemPartner: 'OEM Partner',
  oemPartnerHint: 'OEM partner is not valid',
  expirationDate: 'Expiration Date',
  expirationDateHint: 'Expiration date is not valid',
  receivedDate: 'Received Date',
  receivedDateHint: 'Received date is not valid',
  executedDate: 'Executed Date',
  executedDateHint: 'Executed date is not valid',
  invoicedDate: 'Invoiced Date',
  invoicedDateHint: 'Invoiced date is not valid',
  vehicleMake: 'Vehicle Make',
  vehicleMakeHint: 'Vehicle make is not valid',
  vehicleModel: 'Vehicle Model',
  vehicleModelHint: 'Vehicle model is not valid',
  vehicleYear: 'Vehicle Year',
  vehicleYearHint: 'Vehicle year is not valid',
  isApplicable: 'Is Applicable',
  isApplicableHint: 'Is applicable is not valid',
  confirmAnnualFeeArchive: 'Archive annual fee',
  confirmAnnualFeeArchiveMessage:
    'Are you sure you want to archive the annual fee for <modelYear>?',
  selectOEMPartner: 'Select OEM Partner',
  selectToolPartner: 'Select Tool Partner',
  selectRegion: 'Select Region',
  selectYear: 'Select Year',
  selectFeeFilter: 'Select Annual Fee Filter',
  selectInsuranceFilter: 'Select Insurance Filter',
  selectSupportFilter: 'Select Support Filter',
  oemMfr: 'OEM Mfr',
  toolMfr: 'Tool Mfr',
  region: 'Region',
  make: 'Make',
  optional: 'optional',
  created: 'Created',
  received: 'Received',
  sent: 'Sent',
  signed: 'Signed',
  uploadDoc: 'Upload Doc',
  dragAndDrop: 'Drag & Drop File',
  uploadDocumentSuccess: 'Document uploaded successfully',
  removeDocumentSuccess: 'Document removed successfully',
  compFees: 'Comprehensive Fees',
  applicable: 'Applicable',
  invoiced: 'Invoiced',
  poRecResent: 'PO Rec & Resent',
  paid: 'Paid',
  paidToOem: 'Paid To OEM',
  otherFees: 'Other Fees',
  supportDocs: 'Support Docs',
  insurance: 'Insurance',
  effective: 'Effective',
  expires: 'Expires',
  annualFees: 'Annual Fees',
  modelYear: 'Model Year',
  newYear: 'New Year',
  paidPending: 'Paid Pending',
  confirmDeleteDoc: 'Delete document',
  confirmDeleteDocMessage: 'Are you sure you want to delete this document?',

  // Company Profile
  companyInfo: 'Company Info',
  partnerName: 'Partner Name',
  partnerNameHint: 'Partner name is not valid',
  partnerState: 'Partner State',
  partnerStateHint: 'Partner state is not valid',
  partnerTypeHint: 'Partner Type is not valid',

  // Stats & Trends
  dashboardControls: 'Dashboard Controls',
  range: 'Range',
  yesterday: 'Yesterday',
  today: 'Today',
  lastMonth: 'Last Month',
  thisMonth: 'This Month',
  lastYear: 'Last Year',
  thisYear: 'This Year',
  month: 'Month',

  // enums
  SITE_ADMIN: 'Site Admin',
  OEM_ADMIN: 'OEM Admin',
  OEM_USER: 'OEM User',
  MO_ADMIN: 'MO Admin',
  MO_USER: 'MO User',
  CPO_ADMIN: 'CPO Admin',
  CPO_USER: 'CPO User',
  TOOL_ADMIN: 'Tool Admin', //DELETE THIS
  TOOL_USER: 'Tool User',  //DELETE THIS
  OEM: 'Original Equipment Manufacturer',
  MO: 'Mobility Operator',
  CPO: 'Charge Point Operator',
  TOOL: 'Tool',
  LICENSE_APPLICATION: 'License Application',
  LICENSE_FEE: 'License Fee',
  INSURANCE_DOCS: 'Insurance Docs',
  SUPPORT_DOCS: 'Support Docs',
  NA: 'N/A',
  PAID: 'Paid',
  PO_RECEIVED_AND_RESENT: 'PO Rec & Resent',
  PAID_TO_OEM: 'Paid TO OEM',
  PAID_PENDING: 'Paid Pending',
  INVOICED: 'Invoiced',
  UNPAID: 'Unpaid',
  EXPIRED: 'Expired',
  EXPIRING: 'Expiring',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  RECEIVED: 'Received',
  SENT: 'Sent',
  SIGNED: 'Signed',
  UNSIGNED: 'Unsigned',
  COMPLETE: 'Complete',
  WAITING: 'Waiting',
  NORTHAMERICA: 'North America',
  EUROPE: 'Europe',
  SOUTHAMERICA: 'South America',
  CHINA: 'China',
  JAPAN: 'Japan',
  OCEANIA: 'Oceania',
  INDIA: 'India',
  AFRICA: 'Africa',

  // User Profile
  userDetails: 'User Details',
  changeEmail: 'Change Email',
  usernameLabel: 'Username',
  firstName: 'First Name',
  firstNameHint: 'First name is not valid',
  lastName: 'Last Name',
  lastNameHint: 'Last name is not valid',
  emailAddress: 'Email Address',
  email: 'Email',
  emailHint: 'Email is not valid',
  emailSentMsg: 'Email sent',
  confirmationCode: 'Confirmation Code',
  submitConfirmationCode: 'Submit Confirmation Code',
  sendConfirmationCode: 'Send Confirmation Code',
  confirmationCodeSent: 'A confirmation code has been sent to your email',
  sending: 'Sending...',
  codeSent: 'Verification code sent to email',
  oldPassword: 'Old Password',
  add: 'Add',
  update: 'Update',
  updating: 'Updating...',
  updateSuccess: 'Update Success',
  updateError: 'Update Error',
  newPassword: 'New Password',
  newPasswordHint: 'New password is not valid',
  confirmPassword: 'Confirm Password',
  confirmPasswordHint: 'Confirm password is not valid',
  passwordUpdated: 'Password updated',
  passwordMismatch: 'Password mismatch',
  accountValidationSuccess: 'Account Validation Success',
  resendConfirmCode: 'Resend confirmation code',
  confirming: 'Confirming...',
  accountValidationCodeSent:
    'An account validation code has been sent to your email address. Enter the code below to validate it.',

  // Error Messages
  fieldCannotBeBlank: 'Field cannot be blank',
  enterValidEmail: 'Enter a valid email',
  enterValidName: 'First and Last name cannot be blank and cannot contain special characters',
};
