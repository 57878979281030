export const CORS_ERROR_MESSAGE = 'Failed to fetch';

export enum PARTNER_TYPE {
  OEM = 'OEM',
  MO = 'MO',
  CPO = 'CPO',
}

export enum USER_TYPE {
  CPO_ADMIN   = 'CPO_ADMIN',
  MO_ADMIN    = 'MO_ADMIN',
  OEM_ADMIN   = 'OEM_ADMIN',
  SITE_ADMIN  = 'SITE_ADMIN',
  CPO_USER    = 'CPO_USER',
  MO_USER     = 'MO_USER',
  OEM_USER    = 'OEM_USER',
}

//Improve This VVV
export const USER_TYPE_ADMIN = [USER_TYPE.CPO_ADMIN, USER_TYPE.MO_ADMIN, USER_TYPE.OEM_ADMIN, USER_TYPE.SITE_ADMIN]
export const NO_SITE_ADMIN = [USER_TYPE.CPO_ADMIN, USER_TYPE.MO_ADMIN, USER_TYPE.OEM_ADMIN]

export const USER_TYPE_LIST = Object.keys(USER_TYPE).map(
  (key) => key as USER_TYPE
);

export const PARTNER_TYPE_LIST = Object.keys(PARTNER_TYPE).map(
  (key) => key as PARTNER_TYPE
);

export enum USER_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PARTNER_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PAYMENT_STATES {
  PAST_DUE = 'PAST_DUE',
  TERMINATED = 'TERMINATED',
}

export enum SHOP_STATES {
  SUSPENDED = 'SUSPENDED',
  CHARGEBACKRECEIVED = 'CHARGEBACKRECEIVED',
}

export enum TIME_GRANULARITY {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum INSURANCE_STATUS {
  NA = 'NA',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  ACTIVE = 'ACTIVE',
  RECEIVED = 'RECEIVED',
  INACTIVE = 'INACTIVE',
}

export const INSURANCE_STATUS_LIST = Object.keys(INSURANCE_STATUS).map(
  (key) => key as INSURANCE_STATUS
);

export enum SUPPORT_STATUS {
  NA = 'NA',
  COMPLETE = 'COMPLETE',
  WAITING = 'WAITING',
}

export const SUPPORT_STATUS_LIST = Object.keys(SUPPORT_STATUS).map(
  (key) => key as SUPPORT_STATUS
);

export enum REGIONS {
  NORTHAMERICA = 'NORTHAMERICA',
  EUROPE = 'EUROPE',
  SOUTHAMERICA = 'SOUTHAMERICA',
  CHINA = 'CHINA',
  JAPAN = 'JAPAN',
  OCEANIA = 'OCEANIA',
  INDIA = 'INDIA',
  AFRICA = 'AFRICA',
}

export const REGIONS_LIST = Object.keys(REGIONS).map((key) => key as REGIONS);

export const REGION_INFO = {
  [REGIONS.NORTHAMERICA]: {
    name: 'North America',
    latitude: 42,
    longitude: -100,
  },
  [REGIONS.EUROPE]: {
    name: 'Europe',
    latitude: 53,
    longitude: 19,
  },
  [REGIONS.SOUTHAMERICA]: {
    name: 'South America',
    latitude: -10.5,
    longitude: -59.5,
  },
  [REGIONS.CHINA]: {
    name: 'China',
    latitude: 35.86166,
    longitude: 104.195397,
  },
  [REGIONS.JAPAN]: {
    name: 'Japan',
    latitude: 37,
    longitude: 150.7,
  },
  [REGIONS.OCEANIA]: {
    name: 'Oceania',
    latitude: -14,
    longitude: 139.5,
  },
  [REGIONS.INDIA]: {
    name: 'India',
    latitude: 20.593684,
    longitude: 78.96288,
  },
  [REGIONS.AFRICA]: {
    name: 'Africa',
    latitude: 16,
    longitude: 17,
  },
};
