import { en } from '../lang/en.js';

export const dictionaryList = { en };

export const languageOptions = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  fr_ca: 'Français (Canadien)',
  it: 'Italiano',
  de: 'Deutsche',
  pt_br: 'Português',
  ja: '日本語',
  ko: '한국어',
  zh: '中文',
};
