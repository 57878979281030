import React, {useState, FormEvent} from 'react';
import ReactTable from '../../components/ReactTable';
import { Header } from '../../components/Header';
import { TextOnly, Text } from '../../components/Text';
import { getRCPList } from '../../libs/db-lib';
import { useQuery } from 'react-query';
import { Loading } from '../../components/Loading';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../../components/LoaderButton';
import download from 'downloadjs'

export const RootCertificatePool = () =>{
    // #region State  
    const [search, setSearch]           = useState<string>('');
    const [showDialog, setShowDialog]   = useState<string>('');   
    const [rootCertArr, setRootCertArr] = useState<any[]>([]) 
    const [rootCertObj, setRootCertObj] = useState<any>({})
    const [fileName, setFileName]       = useState<string>('')
    const [fileContent, setFileContent] = useState<string>('')
    // #endregion

    // #region Queries
    const { data: rcpList, isLoading: isLoadingRCPList } = useQuery(
        'getRCPList',
        () => getRCPList(),
      );
    // #endregion

    // #region HandleFunctions
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };
    const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileName(event.target.value);
    };
    const handleCancelDialog = () => {
        setFileName('');
        setFileContent('');
        setShowDialog('');
        setRootCertArr([]);
        setRootCertObj({});
    }
    const confirmDownload = (event: FormEvent) =>{
        event.preventDefault();
        download(fileContent,(fileName+'.der'),'application/der')
        return null
    }
    // #endregion

    // #region Variables
    const columns = [
        {
            Header: 'Common Name',
            accessor: 'commonName',
        },
        {
            Header: 'Organization',
            accessor: 'organizationName',
        },
        {
            Header: 'Root Type',
            accessor: 'rootType',
        },
        {
            Header: 'Valid From',
            accessor: 'validFrom',
            Cell: ({ row: { original } }: any) => {
                return <span
                            title={original.validFrom?.split('T')[1]}
                        >
                            {original.validFrom?.split('T')[0]}
                        </span> 
            },
        },
        {
            Header: 'Valid To',
            accessor: 'validTo',
            Cell: ({ row: { original } }: any) => {
                return <span
                            title={original.validTo?.split('T')[1]}
                        >
                            {original.validTo?.split('T')[0]}
                        </span> 
            },
        },
        {
            Header: 'Actions',
            accessor: 'clientActions',
            Cell: ({ row: { original } }: any) => {
                
                return <div className='l-flex-gap-1'>
                            <button
                                className="c-btn-icon-compact"
                                title={TextOnly('info')}
                                onClick={()=>{
                                    const rcObj = {...original}
                                    delete rcObj.caCertificate
                                    setRootCertArr(Object.entries(rcObj).sort())
                                    setShowDialog('Slider-Info')
                                }}
                            >
                                <div className="c-btn__inner">
                                    <i className="c-btn__icon fa fa-info" />
                                </div>
                            </button>
                            <button
                                className="c-btn-icon-compact"
                                title={TextOnly('download')}
                                onClick={()=>{
                                    const rcObj = {...original}
                                    setFileContent(Buffer.from(rcObj.caCertificate, 'base64').toString('utf-8'));
                                    delete rcObj.caCertificate
                                    setRootCertObj(rcObj)
                                    setFileName(rcObj?.commonName?.replaceAll(' ',''))
                                    setShowDialog('Modal-Download')
                                }}
                            >
                                <div className="c-btn__inner">
                                    <i className="c-btn__icon fa fa-download" />
                                </div>
                            </button>
                        </div>
            },
        },
    ];
    // #endregion
    if (isLoadingRCPList) {
        return <Loading />;
    }
    return (
        <>
            <Header title={TextOnly('rootCertificatePool')} />
            <div className="l-container">
                    <div className="l-flex-between">
                        <div className="c-field u-margin-bottom-none">
                            <label htmlFor="search" className="c-field__label u-is-vishidden">
                                <Text tid="search" />
                            </label>
                            <input
                                type="text"
                                id="search"
                                maxLength={50}
                                className="c-input"
                                placeholder={TextOnly('search')}
                                value={search}
                                onChange={handleSearchChange}
                            />
                            <i className="c-field__input-icon fal fa-search" />
                        </div>
                    </div>
                <div className="u-margin-top-large">
                    <ReactTable
                        columns={columns}
                        data={rcpList.certList}
                        globalFilter={search}
                        filter={[]}
                        tableName="apiClientEnrollments"
                    />
                </div>
            </div>
            <Dialog
                isOpen={showDialog==='Slider-Info'}
                onDismiss={handleCancelDialog}
                className="c-modal-slider"
                aria-label={TextOnly('clientLabel')}
            >
                <button
                className="c-btn-icon c-modal-slider__close"
                onClick={handleCancelDialog}
                type="button"
                >
                    <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-times" />
                    </div>
                </button>
                <h1 className="c-modal__heading">
                    <Text tid="rcinfo" />
                </h1>
                <div className="c-modal__body">
                    {rootCertArr?.map(([key, val])=>{
                        return (
                        <div key={key}>
                            <h2>
                                <Text tid={key} />:
                            </h2>
                            <h2 className='info-modal'>{val}</h2>
                        </div>
                        )
                    })}                    
                </div>
            </Dialog>
            <Dialog
                isOpen={showDialog==='Modal-Download'}
                onDismiss={handleCancelDialog}
                className="c-modal"
                aria-label={TextOnly('rcDownload')}
            >
                <button
                    className="c-btn-icon c-modal__close"
                    onClick={handleCancelDialog}
                    type="button"
                >
                    <div className="c-btn__inner">
                        <i className="c-btn__icon fa fa-times" />
                    </div>
                </button>
                <h1 className="c-modal__heading">
                    <Text tid="rcDownload" />
                </h1>

                <div className="c-modal__body">
                    <h2>
                        <Text tid="certID" />: <span className='color-grey'>{rootCertObj?.certID}</span>
                    </h2>
                    <h2>
                        <Text tid="commonName" />: <span className='color-grey'>{rootCertObj?.commonName}</span>
                    </h2>
                    <h2>
                        <Text tid="organizationName" />: <span className='color-grey'>{rootCertObj?.organizationName}</span>
                    </h2>
                    <div>
                </div>
                <div className="l-flex-between">
                    <div className="c-field u-margin-bottom-none">
                        <label htmlFor="fileName">
                            <h3><Text tid="fileName" /></h3>
                        </label>
                        <input
                            type="text"
                            id="fileName"
                            className="c-input"
                            placeholder={fileName}
                            defaultValue={fileName}
                            onChange={handleFileNameChange}
                        />
                    </div>
                </div>
                <form onSubmit={confirmDownload}>
                    <div className="c-field l-flex-between u-margin-top-xlarge">
                        <button
                            className="c-btn-outline u-margin-right"
                            type="button"
                            onClick={handleCancelDialog}
                        >
                            {TextOnly('cancel')}
                        </button>
                        <LoaderButton
                            type="submit"
                            id="submit-button"
                            disabled={false}
                            isLoading={false}
                            text={TextOnly('download')}
                            loadingText={TextOnly('loading')}
                        />
                    </div>
                </form>
                </div>
            </Dialog>
        </>
    );
}