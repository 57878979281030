import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../components/Header';
import { TextOnly, Text } from '../components/Text';
import { useUser } from '../context/User';

export function MyActivity() {
  const { user } = useUser();

  return (
    <div className="l-container">
      <Header title={TextOnly('myActivity')} />
      {!user?.emailVerified ? (
        <div className="c-notice  c-notice--column">
          <div className="c-notice__content">
            <h2 className="c-notice__title">
              <Text tid="validateEmail" />
            </h2>
            <p className="c-notice__text">
              <Text
                tid="mustValidateEmailMessage"
                sub={{
                  email: <span>{user?.email}</span>,
                }}
              />
            </p>
          </div>
          <div className="c-notice__buttons">
            <Link className="c-btn" to="/userProfile">
              <div className="c-btn__inner">
                <Text tid="userProfile" />
              </div>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}
