import React, { Component } from 'react';
import {
  Navigate,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Loading } from './components/Loading';
import Routes from './Routes';
import { Sidebar } from './containers/Sidebar';
import { MobileSidebar } from './containers/MobileSidebar';
import { useLanguage } from './libs/Language';
import { useUser } from './context/User';
import { TextOnly } from './components/Text';
import { toast } from 'react-toastify';
import logo from './assets/images/ca.png';
import { useMap } from './context/Map';

class App extends Component {
  constructor(props) {
    super(props);

    window.addEventListener(
      'orientationchange',
      this.handleChangeOrientation.bind(this)
    );

    this.state = {
      isAuthenticating: true,
      alertMessage: '',
      revision: 0,
    };
  }

  async componentDidUpdate() {
    if (
      this.props.userContext.user &&
      !this.props.userContext.isAuthenticated
    ) {
      this.setState({ isAuthenticating: false });
    }
  }

  addEventListenerOnce(target, type, listener, addOptions, removeOptions) {
    target.addEventListener(
      type,
      function fn(event) {
        target.removeEventListener(type, fn, removeOptions);
        listener.apply(this, arguments);
      },
      addOptions
    );
  }

  handleChangeOrientation = () => {
    this.addEventListenerOnce(window, 'resize', this.handleResize.bind(this));
  };

  handleResize = () => {
    this.setState({
      revision: this.state.revision < 100 ? this.state.revision + 1 : 0,
    });
  };

  render() {
    const childProps = {
      isAuthenticated: this.props.userContext.isAuthenticated,
      fetchUser: this.props.userContext.fetchUser,
      pathname: this.props.location.pathname,
      user: this.props.userContext.user,
      revision: this.state.revision,
      logout: this.props.userContext.handleLogout,
    };

    // REDIRECTS
    const isLocationWarningRoute = this.props.location.pathname.match(
      /manageShopTools|manageUsers|myActivity|upgradeToPlus/
    );

    const { isOwner } = this.props.userContext;

    // TODO: need to check payment OEM vendor state - Chance Smith 1/14/22
    const isShopSuspended = false;
    const isActiveChargeback = false;

    if (isOwner && isLocationWarningRoute) {
      let toastText = TextOnly('shopSuspendedMessage');
      if (!isShopSuspended) {
        toastText = TextOnly('shopAccountPastDue');
      }
      return toastAndRedirectToProfile(toastText);
    } else {
      if (isOwner && isActiveChargeback) {
        let toastText = TextOnly('chargebackDisputed', {
          link: 'https://info.autoauth.com/contact',
        });
        toast.error(toastText, {
          containerId: 'wide',
          position: 'top-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }

    return (
      <>
        <div className="l-container">
          <div
            className={`l-view-layout l-view-layout--sidebar ${
              childProps.pathname === '/' || childProps.pathname === '/login'
                ? 'l-view--login'
                : ''
            }`}
          >
            {childProps.pathname === '/' || childProps.pathname === '/login' ?
              <img className={"chargeauth-bg"} src={"img/bgtest.jpg"} alt={'Login'}>
              </img>
              : <></>
            }
            <aside className="l-view-layout__sidebar">
              <Sidebar
                userName={this.props.userContext.user?.userName}
                shopName={this.props.userContext.currentShop?.shopName}
                userType={this.props.userContext.user?.userType}
                isAuthenticated={this.props.userContext.isAuthenticated}
                logout={this.props.userContext.handleLogout}
              />
            </aside>
            <div
              className={`l-view-layout__main l-view-layout--two-column ${
                this.props.mapContext.mapIsShown
                  ? 'l-view-layout__main--shadow'
                  : ''
              }`}
            >
              <NavLink
                className="c-primary-nav__link u-margin-top-small u-sidebar-hidden"
                to="/"
              >
                <div className="c-logo">
                  <img src={logo} className="c-logo__image" alt="logo" />
                </div>
              </NavLink>

              <Routes childProps={childProps} />
            </div>
          </div>
          <MobileSidebar
            userName={this.props.userContext.user?.userName}
            shopName={this.props.userContext.currentShop?.shopName}
            userType={this.props.userContext.user?.userType}
            isAuthenticated={this.props.userContext.isAuthenticated}
            logout={this.props.userContext.handleLogout}
          />
        </div>
        <Loading className="spinner-blur u-is-hidden" />
      </>
    );
  }
}

const AppWithRouter = (props) => <App {...props} />;

export default (props) => {
  const languageContext = useLanguage();
  const userContext = useUser();
  const mapContext = useMap();
  const location = useLocation();
  const history = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <AppWithRouter
      languageContext={languageContext}
      userContext={userContext}
      mapContext={mapContext}
      history={history}
      location={location}
      searchParams={searchParams}
      {...props}
    />
  );
};

function toastAndRedirectToProfile(message) {
  toast.error(message, {
    containerId: 'standard',
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
  return <Navigate to={`/shopProfile`} />;
}
