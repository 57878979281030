import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './containers/Login';
import ManageUsers from './containers/ManageUsers';
import { UserProfile } from './containers/UserProfile';
import { CompanyProfile } from './containers/CompanyProfile';
import { ManagePartners } from './containers/ManagePartners';
import NotFound from './containers/NotFound';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import APIClient from './components/APIClient';
import { MyActivity } from './containers/MyActivity';
import { USER_TYPE, USER_TYPE_ADMIN, NO_SITE_ADMIN } from './CONSTANTS';
import RootCertificatePool from './containers/RootCertificatePool';

export default ({ childProps }) => (
  <Routes>
    <Route
      path="/"      
      element={
        childProps.isAuthenticated ? (
          <Navigate to="/myActivity" />
        ) : (
          <Login {...childProps} />
        )
      }
    />
    <Route path="/login" element={<Login {...childProps} />} />
    <Route
      path="/myActivity"
      element={<AuthenticatedRoute component={MyActivity} props={childProps} />}
    />
    <Route
      path="/manageUsers"
      element={
        <AuthenticatedRoute
          component={ManageUsers}
          props={childProps}
          permissions={{
            roles: USER_TYPE_ADMIN,
          }}
        />
      }
    />
    <Route
      path="/managePartners"
      element={
        <AuthenticatedRoute
          component={ManagePartners}
          props={childProps}
          permissions={{
            roles: [USER_TYPE.SITE_ADMIN],
          }}
        />
      }
    />
    <Route
      path="/userProfile"
      element={
        <AuthenticatedRoute component={UserProfile} props={childProps} />
      }
    />
    <Route
      path="/companyProfile"
      element={
        <AuthenticatedRoute component={CompanyProfile} props={childProps} />
      }
    />
    <Route
      path="/APIClient"
      element={
        <AuthenticatedRoute 
            component={APIClient} 
            props={childProps}
            permissions={{
                roles: NO_SITE_ADMIN,
            }}
        />
      }
    />
    <Route
      path="/rootCertificatePool"
      element={<AuthenticatedRoute component={RootCertificatePool} props={childProps} />}
    />
    <Route
      path="*"      
      element={
        childProps.isAuthenticated ? (
          <NotFound />
        ) : (
          <Login {...childProps} />
        )
      }
    />
  </Routes>
);
