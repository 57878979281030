import Dialog from '@reach/dialog';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FilterPills } from '../components/FilterPills';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { Loading } from '../components/Loading';
import ReactTable from '../components/ReactTable';
import { TextOnly, Text } from '../components/Text';
import { PARTNER_STATE, PARTNER_TYPE, PARTNER_TYPE_LIST } from '../CONSTANTS';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  CreatePartnerParams,
  siteAdminCreatePartner,
  getPartners,
  getPartnersResponse,
  updatePartnerInfo,
  UpdatePartnerInfoParams,
} from '../libs/db-lib';
import { Partner } from '../types';

export interface PartnerTableView {
  id: string;
  partnerName: string;
  partnerType: string;
  location: string;
  partnerContactPhone: string;
  partnerState: React.Component | PARTNER_STATE;
  crud: React.Component | JSX.Element;
}

export enum MANAGE_PARTNERS_DIALOGS {
  ADD_PARTNER = 'ADD_PARTNER',
  EDIT_PARTNER = 'EDIT_PARTNER',
  FILTER = 'FILTER',
}

export const ManagePartners = () => {

  const [search, setSearch] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const [currentDialog, setCurrentDialog] =
    useState<MANAGE_PARTNERS_DIALOGS | null>(null);
  const [partnerTypeFilter, setPartnerTypeFilter] = useState<PARTNER_TYPE[]>([]);
  const [statusFilter, setStatusFilter] = useState<PARTNER_STATE[]>([]);
  const [selectedPartner, setSelectedPartner] =
    useState<UpdatePartnerInfoParams | null>(null);

  const siteAdminGetPartnerList = (data: getPartnersResponse) => {
    const partnerList = data?.partnerList?.map((partner: Partner) => {
      return {
        id: partner.partnerID,
        partnerName: partner.partnerName,
        partnerType: partner.partnerType,
        location: `TEST LOCATION}`,
        partnerContactPhone: `0000000000`,
        partnerState: partner.partnerState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedPartner(partner);
                toggleEditPartnerDialog();
              }}
              disabled={partner.partnerState !== PARTNER_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    return partnerList;
  };

  // #region 1 queries
  const {
    data: partnersData,
    isLoading: isLoadingPartners,
    refetch: refetchPartners,
  } = useQuery('getPartners', getPartners);

  const partners = siteAdminGetPartnerList(partnersData || { partnerList: [] });

  // #endregion 1

  // #region 2 mutations
  const siteAdminCreatePartnerMutation = useMutation(
    (partnerFields: CreatePartnerParams) => siteAdminCreatePartner(partnerFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createPartnerSuccess'), {
          containerId: 'standard',
        });
        addPartnerFormik.resetForm();
        setCurrentDialog(null);
        refetchPartners();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const updatePartnerInfoMutation = useMutation<
    { error?: string },
    Error,
    UpdatePartnerInfoParams
  >((partnerFields) => updatePartnerInfo(partnerFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      editPartnerFormik.resetForm();
      setCurrentDialog(null);
      refetchPartners();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  // #endregion 2

  // #region 3 columns
  const columns = [
    {
      Header: 'Name',
      accessor: 'partnerName',
    },
    {
      Header: 'State',
      accessor: 'partnerState',
    },
    {
      Header: 'Type',
      accessor: 'partnerType',
    },
  ];

  // #endregion 3

  // #region 4 formik
  const filterFormik = useFormik({
    initialValues: {
      partnerTypeFilter: partnerTypeFilter,
      statusFilter: statusFilter,
    },
    onSubmit: async (values) => {
      setPartnerTypeFilter(values.partnerTypeFilter);
      setStatusFilter(values.statusFilter);
      toggleFilterDialog();
    },
    enableReinitialize: true,
  });

  const addPartnerFormik = useFormik({
    initialValues: {
      partnerName: '',
      partnerType: PARTNER_TYPE.OEM,
    },
    onSubmit: async (values) => {
      siteAdminCreatePartnerMutation.mutate({
        partnerName: values.partnerName,
        partnerType: values.partnerType,
      });
    },
  });

  const editPartnerFormik = useFormik({
    initialValues: {
      partnerName: selectedPartner?.partnerName || '',
      partnerState: selectedPartner?.partnerState || '',
      partnerType: selectedPartner?.partnerType || '',
    },
    onSubmit: async (values) => {
      if(selectedPartner) updatePartnerInfoMutation.mutate({
        partnerID: selectedPartner.partnerID,
        partnerName: values.partnerName,
        partnerState: values.partnerState,
        partnerType: values.partnerType,
      });
    },
    enableReinitialize: true,
  });

  // #endregion 4

  // #region 5 funcitons

  const toggleFilterDialog = () => {
    currentDialog === MANAGE_PARTNERS_DIALOGS.FILTER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_PARTNERS_DIALOGS.FILTER);
  };

  const toggleAddPartnerDialog = () => {
    currentDialog === MANAGE_PARTNERS_DIALOGS.ADD_PARTNER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_PARTNERS_DIALOGS.ADD_PARTNER);
  };

  const toggleEditPartnerDialog = () => {
    currentDialog === MANAGE_PARTNERS_DIALOGS.EDIT_PARTNER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_PARTNERS_DIALOGS.EDIT_PARTNER);
  };

  const resetFilters = () => {
    setPartnerTypeFilter([]);
    setStatusFilter([]);
    setCurrentDialog(null);
  };
  // #endregion 5
  function handleFilterDelete({
    field,
    value,
  }: {
    field: string;
    value: string;
  }) {
    if (field === 'userType') {
      setPartnerTypeFilter([]);
    } else if (field === 'userState') {
      setStatusFilter([]);
    }
  }
  const userTypeFilter =
    partnerTypeFilter.length > 0
      ? {
          userType: [
            {
              key: partnerTypeFilter[0],
              value: partnerTypeFilter[0],
              label: partnerTypeFilter[0],
              pillLabel: partnerTypeFilter[0],
            },
          ],
        }
      : null;

  const userStateFilter =
    statusFilter.length > 0
      ? {
          userState: [
            {
              key: statusFilter[0],
              value: statusFilter[0],
              label: statusFilter[0],
              pillLabel: statusFilter[0],
            },
          ],
        }
      : null;

  const filters = {
    ...userTypeFilter,
    ...userStateFilter,
  };

  if (isLoadingPartners) {
    return <Loading />;
  }

  return (
    <>
      <Header title={TextOnly('managePartners')} />
      <div className="l-container">
        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <button className="c-btn" onClick={toggleAddPartnerDialog}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="addPartners" />
                </div>
              </button>
            </div>
          </div>
          <div className="l-flex-between">
            <button
              className="c-btn-outline u-margin-right"
              onClick={toggleFilterDialog}
            >
              <div className="c-btn__inner">
                <Text tid="filterBy" />
                <i className="c-btn__icon fas fa-caret-down" />
              </div>
            </button>
            <div className="c-field u-margin-bottom-none">
              <label htmlFor="search" className="c-field__label u-is-vishidden">
                <Text tid="search" />
              </label>
              <input
                type="text"
                id="search"
                maxLength={50}
                className="c-input"
                placeholder={TextOnly('search')}
                value={search}
                onChange={handleChange}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>

        {Object.keys(filters).length ? (
          <div className="l-flex-wrap u-margin-top-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link-text u-margin-right"
                onClick={resetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills
                filters={filters}
                onFilterClick={handleFilterDelete}
              />
            </div>
          </div>
        ) : null}

        <div className="u-margin-top-large">
          <ReactTable
            columns={columns}
            data={partners}
            globalFilter={search}
            filter={[
              { columnId: 'partnerType', filter: partnerTypeFilter[0] },
              { columnId: 'partnerState', filter: statusFilter[0] },
            ]}
            tableName="managePartners"
          />
        </div>
      </div>
      <Dialog
        isOpen={currentDialog === MANAGE_PARTNERS_DIALOGS.FILTER}
        onDismiss={toggleFilterDialog}
        className="c-modal-slider"
        aria-label={TextOnly('filters')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleFilterDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="filters" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={filterFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="partnerType" />
                </label>
                <Typeahead
                  id="partnerTypeFilter"
                  placeholder="Select Partner Type"
                  options={PARTNER_TYPE_LIST}
                  selected={filterFormik.values.partnerTypeFilter}
                  onChange={(selected) => {
                    filterFormik.setFieldValue('partnerTypeFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="status" />
                </label>
                <Typeahead
                  id="statusFilter"
                  placeholder="Select Partner Status"
                  options={[PARTNER_STATE.ACTIVE, PARTNER_STATE.INACTIVE]}
                  selected={filterFormik.values.statusFilter}
                  onChange={(selected) => {
                    filterFormik.setFieldValue('statusFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              <div className="c-field l-flex-between">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={resetFilters}
                >
                  <Text tid="resetFilters" />
                </button>
                <button className="c-btn" type="submit">
                  <Text tid="applyFilters" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_PARTNERS_DIALOGS.ADD_PARTNER}
        onDismiss={toggleAddPartnerDialog}
        className="c-modal-slider"
        aria-label={TextOnly('addPartner')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleAddPartnerDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="addPartner" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={addPartnerFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">Partner Type</label>
                <div className="c-select">
                  <select
                    id="partnerType"
                    name="partnerType"
                    value={addPartnerFormik.values.partnerType}
                    onChange={(e) => {
                      addPartnerFormik.setFieldValue(
                        'partnerType',
                        e.target.value
                      );
                    }}
                  >
                    {PARTNER_TYPE_LIST.map((partnerType) => (
                      <option key={partnerType} value={partnerType}>
                        {TextOnly(partnerType)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="c-field">
                <label htmlFor="partnerName" className="c-field__label">
                  <Text tid="partnerName" />:
                </label>
                <input
                  id="partnerName"
                  name="partnerName"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('partnerName')}
                  value={addPartnerFormik.values.partnerName}
                  onChange={addPartnerFormik.handleChange}
                />
                {addPartnerFormik.errors.partnerName ? (
                  <div className="c-field__error">
                    <Text tid="partnerNameHint" />
                  </div>
                ) : null}
              </div>

              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!addPartnerFormik.isValid}
                isLoading={
                  addPartnerFormik.isSubmitting ||
                  siteAdminCreatePartnerMutation.isLoading
                }
                text={TextOnly('addPartner')}
                loadingText={TextOnly('adding')}
              />
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_PARTNERS_DIALOGS.EDIT_PARTNER}
        onDismiss={toggleEditPartnerDialog}
        className="c-modal-slider"
        aria-label={TextOnly('editPartner')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleEditPartnerDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="editPartner" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={editPartnerFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label htmlFor="partnerName" className="c-field__label">
                  <Text tid="partnerName" />:
                </label>
                <input
                  id="partnerName"
                  name="partnerName"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('partnerName')}
                  value={editPartnerFormik.values.partnerName}
                  onChange={editPartnerFormik.handleChange}
                />
                {editPartnerFormik.errors.partnerName ? (
                  <div className="c-field__error">
                    <Text tid="partnerNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="partnerState" className="c-field__label">
                  <Text tid="partnerState" />:
                </label>
                <input
                  id="partnerState"
                  name="partnerState"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('partnerState')}
                  value={editPartnerFormik.values.partnerState}
                  onChange={editPartnerFormik.handleChange}
                />
                {editPartnerFormik.errors.partnerState ? (
                  <div className="c-field__error">
                    <Text tid="partnerStateHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="partnerType" className="c-field__label">
                  <Text tid="partnerType" />:
                </label>
                <input
                  id="partnerType"
                  name="partnerType"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('partnerType')}
                  value={editPartnerFormik.values.partnerType}
                  onChange={editPartnerFormik.handleChange}
                />
                {editPartnerFormik.errors.partnerType ? (
                  <div className="c-field__error">
                    <Text tid="partnerTypeHint" />
                  </div>
                ) : null}
              </div>
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!editPartnerFormik.isValid}
                isLoading={
                  editPartnerFormik.isSubmitting ||
                  updatePartnerInfoMutation.isLoading
                }
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
