import React, { useState } from 'react';
import AlertModal from '../components/AlertModal';

interface ConfirmProps {
  title: string;
  message: string;
  BtnText?: string;
  ConfirmBtnText?: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

type ConfirmProviderType = {
  children?: JSX.Element;
  showConfirmModal: boolean;
  toggleShowConfirmModal: () => void;
  confirmProps: ConfirmProps | null;
  handleSetConfirmProps: (confirmProps: ConfirmProps) => void;
};

const menuContext: ConfirmProviderType = {
  showConfirmModal: false,
  toggleShowConfirmModal: () => {},
  confirmProps: null,
  handleSetConfirmProps: (confirmProps: ConfirmProps) => {},
};

export const ConfirmContext = React.createContext(menuContext);

export function useConfirm() {
  return React.useContext(ConfirmContext);
}

// it provides the language context to app
export function ConfirmProvider(props: ConfirmProviderType) {
  const [showConfirmModal, setShowConfirmModal] = React.useState(
    props.showConfirmModal
  );
  const [confirmProps, setConfirmProps] = useState<ConfirmProps | null>(
    props.confirmProps
  );

  const handleSetConfirmProps = (confirmProps: ConfirmProps) => {
    setConfirmProps(confirmProps);
    setShowConfirmModal(true);
  };

  const toggleShowConfirmModal = () => {
    if (showConfirmModal) {
      setConfirmProps(null);
    }

    setShowConfirmModal(!showConfirmModal);
  };

  const closeModal = () => {
    setShowConfirmModal(false);
    if (confirmProps?.handleCancel) confirmProps?.handleCancel();
  };

  const confirmModal = () => {
    setShowConfirmModal(false);
    if (confirmProps?.handleConfirm) confirmProps?.handleConfirm();
  };

  const provider = {
    showConfirmModal,
    toggleShowConfirmModal,
    confirmProps,
    handleSetConfirmProps,
  };

  return (
    <ConfirmContext.Provider value={provider}>
      {props.children}
      {confirmProps ? (
        <AlertModal
          title={confirmProps.title}
          message={confirmProps.message}
          showModal={showConfirmModal}
          handleCancel={closeModal}
          handleConfirm={confirmProps?.handleConfirm ? confirmModal : undefined}
          BtnText={confirmProps.BtnText}
          ConfirmBtnText={confirmProps.ConfirmBtnText}
        />
      ) : null}
    </ConfirmContext.Provider>
  );
}
