import React, { FC } from 'react';
import loading from '../assets/icons/loading.svg';

type Props = {
  className?: string;
};

export const Loading: FC<Props> = (props) => (
  <div className={props.className || 'spinner'}>
    <img src={loading} alt="Loading" />
  </div>
);
