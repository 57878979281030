export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://sg9r6m35f0.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_YgBeuTKuC",
    APP_CLIENT_ID: "3umi05niphrqjgufe5tgrc681j",
    IDENTITY_POOL_ID: "us-west-2:2ce75dd8-7e3e-4f74-945d-714d90d92ff4"
  }
};
