import React from 'react';

interface ToggleButtonProps {
  title: string;
  isActive: boolean;
  handleToggle: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ToggleButton = ({
  title,
  isActive,
  handleToggle,
  disabled,
  isLoading,
}: ToggleButtonProps) => {
  return (
    <label
      className={`c-toggle ${isActive ? 'c-toggle--active' : ''} ${
        disabled || isLoading ? 'c-toggle--disabled' : ''
      }`}
      title={title}
      onClick={handleToggle}
    >
      {isActive ? (
        <span className="c-toggle__label-active">Active</span>
      ) : (
        <span className="c-toggle__label-inactive">Inactive</span>
      )}
      <span className="c-toggle__handle" />
    </label>
  );
};
